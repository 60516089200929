import React from "react";
import { Layout } from "../components/Layout";
import { services } from "../data/services";
import { ContactPop } from "./Contact";

export const ProductView = ({ match }) => {
	const [data, setData] = React.useState();

	React.useEffect(() => {
		const { title } = match?.params;
		const json = services.find((e) => e.link.includes(title));
		setData(json);
	}, [match]);

	return (
		<Layout>
			<section
				className="cover-background"
				data-parallax-background-ratio="0.5"
				style={{
					background: `rgba(0, 0, 0, 0) url("${
						data?.bg ||
						require("../media/images/blog-post-layout-04-img-01.jpg").default
					}") repeat scroll 50% 0px`,
				}}
			>
				<div className="opacity-extra-medium-2 bg-extra-dark-gray" />
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-12 col-md-10 one-half-screen d-flex justify-content-end flex-column sm-h-400px">
							<h2 className="alt-font text-dark font-weight-500 margin-5-half-rem-bottom w-85 lg-w-100 text-capitalize">
								{data?.title}
							</h2>
							{data?.description2 && (
							<h6 className="text-extra-dark-gray font-weight-500"
								style= {{
									color: "#fff",
									// background: "black",
									padding: "2rem",
									borderRadius: "1rem",
									paddingLeft: 0
								}}
								dangerouslySetInnerHTML={{ __html: data?.description2 }}
							/>
							)}
							<ul className=" list-unstyled">
								<li className="d-block d-sm-inline-block margin-50px-right xs-margin-10px-bottom xs-no-margin-right">
									<span className="text-dark opacity-5 d-block">
										Last Updated
									</span>
									<a href="blog-grid.html" className="text-dark alt-font">
										9 August 2021
									</a>
								</li>
								<li className="d-block d-sm-inline-block margin-50px-right xs-no-margin-right">
									<span className="text-dark opacity-5 d-block">
										Categories
									</span>
									<a href="blog-grid.html" className="text-dark alt-font">
										2nd medical opinion
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
{/* 
			<section>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-md-12 last-paragraph-no-margin sm-margin-30px-bottom">
							<h6 className="text-extra-dark-gray font-weight-500"
								dangerouslySetInnerHTML={{ __html: data?.description2 }}
							/>
							<p dangerouslySetInnerHTML={{ __html: data?.description1 }} />
						</div>
						<div className="col-12 col-lg-4 offset-lg-1 col-md-5">
							<div
								className="align-items-start h-100 justify-content-center d-flex flex-column padding-4-half-rem-all bg-fast-blue border-radius-10px background-no-repeat background-position-right-bottom lg-padding-3-rem-all"
								style={{
									backgroundImage: `url("${
										require("../media/images/blog-post-layout-01-img-11.png")
											.default
									}")`,
								}}
							>
								<i className="fas fa-quote-left text-dark icon-extra-medium margin-30px-bottom" />
								<h6 className="text-extra-dark-gray letter-spacing-minus-1 w-100">
									Style is a way to say who you are without having to speak
								</h6>
								<span className="alt-font text-uppercase text-medium text-dark">
									Rachel Landin
								</span>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <section className="pt-0 bg-dark-1 p-0 mb-5">
				<div className="container mt-5 mb-5">
					<div className="row justify-content-center z-index-0 position-relative pb-5 pt-5">
						<div
							className="col-12 tilt-box wow animate__fadeIn border-radius-5px"
							data-tilt-options='{ "maxTilt": 20, "perspective": 1000, "easing": "cubic-bezier(.03,.98,.52,.99)", "scale": 1, "speed": 500, "transition": true, "reset": true, "glare": false, "maxGlare": 1 }'
							style={{
								visibility: "visible",
								animationName: "fadeIn",
								willChange: "transform",
								transform: "perspective(1000px) rotateX(0deg) rotateY(0deg)",
							}}
						>
							<div
								className="cover-background one-third-screen d-flex justify-content-center flex-column no-padding-tb border-radius-5px"
								style={{
									background: `url("${
										require("../media/img/iStock-695349930.jpg").default
									}")`,
								}}
							>
								<div className="opacity-light bg-extra-dark-gray" />
								<a
									href="https://www.youtube.com/watch?v=1hV7NBT0pIY"
									className="popup-youtube video-icon-box video-icon-large text-center"
								>
									<span>
										<span className="video-icon bg-white">
											<i className="icon-simple-line-control-play text-extra-dark-gray" />
											<span className="video-icon-sonar">
												<span className="video-icon-sonar-bfr bg-extra-dark-gray opacity-7" />
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{data?.services && (
				<section
					className="bg-light-gray wow animate__fadeIn margin-5-rem-bottom margin-5-rem-top"
					style={{ visibility: "visible", animationName: "fadeIn" }}
				>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-6 text-center margin-5-rem-bottom sm-margin-3-rem-bottom">
								<span className="alt-font margin-10px-bottom d-inline-block text-uppercase font-weight-500 text-fast-blue letter-spacing-1px">
									Unlimited Possibilities
								</span>
								<h5 className="alt-font text-extra-dark-gray font-weight-600 letter-spacing-minus-1px text-capitalize">
									{data?.title}
								</h5>
							</div>
						</div>
						<div className="row row-cols-1 row-cols-lg-3 row-cols-md-1 justify-content-center">
							{/* start services item */}
							{data?.services?.map((service, i) => {
								return (
									<div
										className="col col-md-9 margin-2-rem-bottom md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
										style={{ visibility: "visible", animationName: "fadeIn" }}
									>
										<div className="feature-box text-left box-shadow-large box-shadow-double-large-hover bg-white padding-4-rem-all lg-padding-3-rem-all md-padding-4-half-rem-all">
											<div className="feature-box-content">
												<span className="margin-15px-bottom d-block text-extra-medium">
													{String(i + 1).padStart(2, "0")}
												</span>
												<h6 className="alt-font font-weight-600 d-block text-extra-dark-gray text-capitalize">
													{service?.name}
												</h6>
												<p>{service?.description}</p>
											</div>
											<div className="feature-box-overlay bg-white" />
										</div>
									</div>
								);
							})}
							{/* end services item */}
						</div>
					</div>
				</section>
			)}

			<section
				className="pt-0 padding-ten-lr padding-five-bottom xl-padding-three-lr md-padding-2-half-rem-lr sm-no-padding-lr wow animate__fadeIn margin-3-rem-top"
				style={{ visibility: "visible", animationName: "fadeIn" }}
			>
				<div className="bg-gradient-peacock-blue-crome-yellow border-radius-5px overflow-hidden padding-9-rem-top md-padding-6-rem-top padding-9-rem-bottom md-padding-6-rem-bottom">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div
								className="col-12 col-xl-5 col-lg-6 offset-xl-1 col-md-8 order-lg-2 md-padding-6-rem-bottom text-center text-lg-left wow animate__fadeIn"
								data-wow-delay="0.4s"
								style={{
									visibility: "visible",
									animationDelay: "0.4s",
									animationName: "fadeIn",
								}}
							>
								<span className="alt-font font-weight-500 text-large text-extra-dark-gray text-decoration-line-bottom d-inline-block margin-35px-bottom letter-spacing-minus-1-half">
								24Telemed
								</span>
								<h4 className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-45px-bottom">
									Consult our team of specialists abroad for your{" "}
									<span className="font-weight-600 text-capitalize">
										{data?.title}
									</span>{" "}
									now
								</h4>
								{/* <a href="#" className="btn text-dark btn text-dark-large btn text-dark-expand-ltr text-dark section-link">Join the people<span className="bg-extra-dark-gray" /></a> */}
								<a
									href="#contact-form"
									className="btn text-dark btn text-dark-medium popup-with-form btn text-dark-transparent-white btn text-dark-rounded btn text-dark-slide-right-bg margin-15px-top"
								>
									Book Appointment Now 
									<span className="bg-white" />
								</a>
							</div>
							<div
								className="col-12 col-lg-6 order-lg-1 text-center align-self-end wow animate__fadeIn"
								data-wow-delay="0.2s"
								style={{
									visibility: "visible",
									animationDelay: "0.2s",
									animationName: "fadeIn",
								}}
							>
								<img
									src={data?.image}
									alt=""
									data-no-retina
									className="border-radius-10px"
									style={{ maxHeight: 450 }}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<ContactPop room={data?.title} price={500} />
		</Layout>
	);
};
