export const socialMedial = [
    {
        name: "Instagram",
        link: "https://www.instagram.com/24telemed/",
        icon: "fab fa-instagram",
        className: "instagram",
    },
    {
        name: "Twitter",
        link: "https://www.twitter.com/24telemed",
        icon: "fab fa-twitter",
        className: "twitter",
    },
    {
        name: "Youtube",
        link: "https://www.youtube.com/@24TELEMED",
        icon: "fab fa-youtube",
        className: "youtube",
    },
];