/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { apiGet } from '../../api/connect';
import { postLink } from './BlogSection';



export const formatDate = (date) => {
    return date;
}


export const BlogItem = ({ post, grid=3, slug="blogs" }) => {
    const [media, setMedia] = React.useState();
    const [categories, setCagories] = React.useState([])

	React.useEffect(() => {
		if(post?.featured_media){
            apiGet({}, "/media/" + post?.featured_media).then((res) => {
                setMedia(res?.guid?.rendered);
            });
            apiGet({}, "/categories?post=" + post?.id).then((res) => {
                setCagories(res);
            });
        }
	}, []);
    // 
    return (
        <div className={`col-xl-${grid} blog-list-item-d ${categories.map((cat) => { return (" "+cat.slug+" ").replace(/,/g," ") })} wow animate__fadeIn`}
         data-wow-delay="0.2s" style={{ animation: '0s ease 0s 1 normal none running none', marginBottom: 35}}>
  <div className="blog-post border-radius-5px bg-white box-shadow-medium">
    <div className="blog-post-image bg-medium-slate-blue">
      <a href={postLink(post, slug)} title><img src={media || "https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-6.jpg"} alt="" data-no-retina /></a>
      <a href={postLink(post, slug)} className="blog-category alt-font text-white">{categories?.length > 0 && categories[0].name}</a>
    </div>
    <div className="post-details padding-3-rem-lr padding-2-half-rem-tb">
      <a href={postLink(post, slug)} className="alt-font text-small d-inline-block margin-10px-bottom">{formatDate(post?.date)}</a>
      <a href={postLink(post, slug)} className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray margin-15px-bottom d-block">{post?.title?.rendered}</a>
      
    </div>
  </div>
</div>

    )
}



const BlogList = ({ q, hideCategories=false, grid=4, hidePagination=true , slug="blogs" }) => {
    const [posts, setPosts] = React.useState([]);
    const [categories, setCagories] = React.useState([]);

    React.useEffect(() => {
        apiGet({}, "/posts"+(q || window.location.search))
            .then(res => {
                setPosts(res);
            });
          if(!hideCategories){
            apiGet({}, "/categories").then((res) => {
                setCagories(res);
            });
          }
    }, [])


    return (
        <section className="bg-light-gray pt-0 padding-ten-lr xl-padding-two-lr lg-padding-three-lr sm-no-padding-lr">
          {!hideCategories && (
  <div className="container">
    <div className="row">
      <div className="col-12 text-center mt-5">
        {/* start filter navigation */}
        <ul className="blog-filter grid-filter nav nav-tabs justify-content-center border-0 text-uppercase font-weight-500 alt-font padding-6-rem-bottom md-padding-4-half-rem-bottom sm-padding-2-rem-bottom">
          <li className="nav active"><a data-filter="*" href="#">All</a></li>
          {categories.map((cat, i) => {
              return <li className="nav"><a data-filter={"."+cat.slug} href="#">{cat.name}</a></li>
          })}
        </ul>
        {/* end filter navigation */}
      </div>
    </div>
  </div>
          )}
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 blog-content">
        <div className="row blog-grid blog-clean gutter-extra-large" style={{ position: 'relative'  }}>
          {/* start blog item */}
          {
              posts.map((post, i) => {
                  return (<BlogItem key={"shdhdhdh-blog-"+i} post={post} grid={grid} slug={slug} /> )
              })
          }
          
        </div>
      </div>                    
      {/* start pagination */}
      {!hidePagination && (

      <div className="col-12 d-flex justify-content-center margin-7-half-rem-top lg-margin-5-rem-top wow animate__fadeIn" style={{visibility: 'visible', animationName: 'fadeIn'}}>
        <ul className="pagination pagination-style-01 text-small font-weight-500 align-items-center">
          <li className="page-item"><a className="page-link" href="#"><i className="feather icon-feather-arrow-left icon-extra-small d-xs-none" /></a></li>
          <li className="page-item"><a className="page-link" href="#">01</a></li>
          <li className="page-item active"><a className="page-link" href="#">02</a></li>
          <li className="page-item"><a className="page-link" href="#">03</a></li>
          <li className="page-item"><a className="page-link" href="#">04</a></li>
          <li className="page-item"><a className="page-link" href="#"><i className="feather icon-feather-arrow-right icon-extra-small  d-xs-none" /></a></li>
        </ul>
      </div>
      )}
      {/* end pagination */}
    </div>
  </div>
</section>


    )
}

export default BlogList
