import React, { useEffect, useState } from "react";
import { testimonial, partners, team } from "./constants";
import { Layout } from "../components/Layout";
import { ModalBox, RoomItemCard, SingleRoom } from "./Rooms";

import { RevSlider2 } from "./RevSlider2";
import { AfterSlider } from "./AfterSlider";
import { Home2ndOpinion } from "./Home2ndOpinion";
import BlogSection from "./Blogs/BlogSection";
import TeamWithCarousel from "../components/TeamWithCarousel";
import HomePartnersWithCarousel from "../components/HomePartnersWithCarousel";
import { CallToActionSP } from "./ServicePage";



export const Home = ({ match }) => {
	const type = (match?.params?.type || "").replace(/home/g, "");
	const source = require("../data/home.json");
	const data = source[type];
	let {
		fancy = [],
		slider = [],
		features = [],
		about,
		location,
	} = data || source.female;

	return (
		<Layout>
			<div>
				<RevSlider2 />
				<AfterSlider />

				{/* end section */}

				<section
					className="p-0 bg-iris-blue wow animate__fadeIn"
					style={{ visibility: "visible", animationName: "fadeIn", backgroundColor: "#f0f9f9"}}
				>
					<div className="container-fluid">
						<div className="row">
							<div
								className="col-12 col-lg-12 padding-8-half-rem-tb padding-10-half-rem-lr xl-padding-7-half-rem-all lg-padding-4-half-rem-all md-padding-5-half-rem-all xs-padding-5-rem-lr wow animate__fadeIn"
								data-wow-delay="0.6s"
								style={{
									visibility: "visible",
									animationDelay: "0.6s",
									animationName: "fadeIn",
								}}
							>
								<h4 className="alt-font font-weight-600 text-dark letter-spacing-minus-1px w-65 margin-2-half-rem-bottom xl-w-100">
									Secure, Simple &amp; Affordable
								</h4>
								<p className="w-60 xl-w-100 text-dark alt-font text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">
								24Telemed is a secure, simple, and affordable electronic health service created to saves lives, give you peace of mind and care that fits your lifestyle.
									Connect with qualified and experienced doctors 24/7 wherever you are and at your convenience.
								</p><br/>
								<p className="w-60 xl-w-100 text-dark alt-font text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">
									The  24Telemed Healthcare Agency is offering affordable healthcare access to residents of Nigeria. 
									Residents will be able to access healthcare services via phone call through dedicated lines.<br/><br/>
									With qualified and skilled doctors available to help you manage your medical conditions
									24 hours 7 days a week Doctors call center, Anytime! Anywhere! For urgent and non-urgent medical care! For referral to medical specialists!
								</p>
							</div>
						</div>
					</div>
				</section>

				{/* start section */}

				{/* end section */}

				{/* <BlogSection /> */}
				{/* end section */}
				{/* <CallToActionSP /> */}
			</div>
		</Layout>
	);
};

export const OurTeam = ({ bg, partner, count }) => {
	return <TeamWithCarousel title='Meet Our Team' team={team} />;
};