import React from "react";
import { initRevPlugin } from "../components/resliderPlugin";
import { ContactPop } from "./Contact";

export const RevSlider2 = () => {
  React.useEffect(() => {
    initRevPlugin();
  }, []);

  return (
    <>
      <section className="p-0 example home-startup bg-dark-slate-blue">
        <article className="content">
          <div
            id="rev_slider_26_1_wrapper"
            className="rev_slider_wrapper fullscreen-container"
            data-alias="mask-showcase"
            data-source="gallery"
          >
            {/* Start revolution slider 5.4.1 fullscreen mode */}
            <div
              id="rev_slider_26_1"
              className="rev_slider fullscreenbanner"
              style={{ display: "none" }}
              data-version="5.4.1"
            >
              <ul>
                {/* start slide 01 */}
                <li
                  data-index="rs-73"
                  data-transition="zoomout"
                  data-slotamount="default"
                  data-hideafterloop={0}
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed={1500}
                  data-thumb="http://works.themepunch.com/revolution_5_3/wp-content/"
                  data-rotate={0}
                  data-saveperformance="off"
                  data-title={1}
                  data-param1={1}
                  data-description
                >
                  {/* main image */}
                  <img
                    alt
                    src={
                      require("../media/images/pexels-ivan-samkov-4989167.jpg")
                        .default
                    }
                    data-bgcolor="#262b32"
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgrepeat="no-repeat"
                    data-bgparallax="off"
                    className="rev-slidebg"
                    data-no-retina
                  />
                  <div
                    className="overlay-bg bg-extra-dark-gray"
                    style={{ opacity: "0.5" }}
                  />
                  {/* main text layer */}
                  <div
                    className="tp-caption tp-resizeme alt-font text-white font-weight-600 text-center"
                    id="slide-411-layer-01"
                    data-frames='[{"delay":200,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-type="text"
                    data-whitespace="nowrap"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['-50','-50','-115','-65']"
                    data-width="auto"
                    data-height="auto"
                    data-fontsize="['70','53','60','35']"
                    data-lineheight="['70','59','70','39']"
                    data-letterspacing="['-2','-1','-1','-1']"
                    data-responsive="off"
                    data-responsive_offset="off"
                    data-paddingtop="['0','0','0','0']"
                    data-paddingbottom="['15','8','8','8']"
                    data-paddingright="['0','0','0','0']"
                    data-paddingleft="['0','0','0','0']"
                    style={{ textShadow: "0 0 20px rgba(0,0,0,0.3)" }}
                  >
                    Online 2nd
                    <br />
                    Medical Opinion
                  </div>
                  {/* small text layer */}
                  <div
                    className="tp-caption tp-resizeme alt-font text-dark font-weight-300 text-center"
                    id="slide-411-layer-02"
                    data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-type="text"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['65','100','-5','15']"
                    data-width="auto"
                    data-height="auto"
                    data-fontsize="['19','16','19','14']"
                    data-lineheight="['28','14','23','20']"
                    data-letterspacing="['0.5','0.5','0.5','0.5']"
                    data-responsive="off"
                    data-responsive_offset="on"
                  >
                    {/* By A.S.E.S.S. CARE */}
                  </div>
                  {/* btn text-dark layer */}
                  <a
                    className="tp-caption tp-resizeme rs-btn btn btn-rounded d-flex text-light align-items-center justify-content-center"
                    // href="#start-now"
                    href="/service/medical-2nd-opinion"
                    id="slide-411-layer-03"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['152','130','82','80']"
                    data-whitespace="nowrap"
                    data-type="button"
                    data-responsive="off"
                    data-responsive_offset="off"
                    data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-textalign="['center','center','center','center']"
                    data-paddingtop="['8','8','8','8']"
                    data-paddingbottom="['8','8','8','8']"
                    data-paddingright="['7','7','7','7']"
                    data-paddingleft="['34','34','34','34']"
                    style={{
                      backgroundColor: "#37514B",
                      color: "#fff",
                      fontSize: 15,
                    }}
                  >
                    Get a Second Opinion Now
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 38,
                        height: 38,
                        borderRadius: "50%",
                        background: "#fff",
                        marginLeft: 19,
                      }}
                    >
                      <i
                        className="fas fa-play"
                        style={{
                          color: "#37514B",
                          fontSize: 12,
                          lineHeight: 15,
                          marginTop: 2,
                          marginLeft: 3,
                        }}
                      />
                    </div>
                  </a>
                </li>
                {/* end slide 01 */}
                {/* start slide 02 */}
                <li
                  data-index="rs-74"
                  data-transition="zoomout"
                  data-slotamount="default"
                  data-hideafterloop={0}
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed={1500}
                  data-thumb="http://works.themepunch.com/revolution_5_3/wp-content/"
                  data-rotate={0}
                  data-saveperformance="off"
                  data-title={2}
                  data-param1={2}
                  data-description
                >
                  {/* main image */}
                  <img
                    src={
                      require("../media/images/pexels-ivan-samkov-4989164.jpg")
                        .default
                    }
                    alt
                    data-bgcolor="#262b32"
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgrepeat="no-repeat"
                    data-bgparallax="off"
                    className="rev-slidebg"
                    data-no-retina
                  />
                  <div
                    className="overlay-bg bg-extra-dark-gray"
                    style={{ opacity: "0.5" }}
                  />
                  {/* main text layer */}
                  <div
                    className="tp-caption tp-resizeme alt-font text-white font-weight-600 text-center"
                    id="slide-411-layer-04"
                    data-frames='[{"delay":200,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-type="text"
                    data-whitespace="nowrap"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['-50','-50','-115','-65']"
                    data-width="auto"
                    data-height="auto"
                    data-fontsize="['70','53','60','35']"
                    data-lineheight="['70','59','70','39']"
                    data-letterspacing="['-2','-1','-1','-1']"
                    data-responsive="off"
                    data-responsive_offset="off"
                    data-paddingtop="['0','0','0','0']"
                    data-paddingbottom="['15','8','8','8']"
                    data-paddingright="['0','0','0','0']"
                    data-paddingleft="['0','0','0','0']"
                    style={{ textShadow: "0 0 20px rgba(0,0,0,0.3)" }}
                  >
                    24Telemed
                    <br/>eConsult 
                  </div>
                  {/* small text layer */}
                  <div
                    className="tp-caption tp-resizeme alt-font text-dark font-weight-300 text-center"
                    id="slide-411-layer-05"
                    data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-type="text"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['65','100','-5','15']"
                    data-width="auto"
                    data-height="auto"
                    data-fontsize="['19','13','19','14']"
                    data-lineheight="['28','14','23','20']"
                    data-letterspacing="['0.5','0.5','0.5','0.5']"
                    data-responsive="off"
                    data-responsive_offset="on"
                  >
                    {/* From around the world in few minutes */}
                  </div>
                  {/* btn text-dark layer */}
                  <a
                    className="tp-caption tp-resizeme rs-btn btn btn-rounded d-flex text-light align-items-center justify-content-center"
                    href="https://econsult.24telemed.org/"
                    id="slide-411-layer-06"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['152','130','82','80']"
                    data-whitespace="nowrap"
                    data-type="button"
                    data-responsive="off"
                    data-responsive_offset="off"
                    data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-textalign="['center','center','center','center']"
                    data-paddingtop="['8','8','8','8']"
                    data-paddingbottom="['8','8','8','8']"
                    data-paddingright="['7','7','7','7']"
                    data-paddingleft="['34','34','34','34']"
                    style={{
                      backgroundColor: "#37514B",
                      color: "#fff",
                      fontSize: 15,
                    }}
                    target={"_blank"}
                  >
                    Get started now
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 38,
                        height: 38,
                        borderRadius: "50%",
                        background: "#fff",
                        marginLeft: 19,
                      }}
                    >
                      <i
                        className="fas fa-play"
                        style={{
                          color: "#37514B",
                          fontSize: 12,
                          lineHeight: 15,
                          marginTop: 2,
                          marginLeft: 3,
                        }}
                      />
                    </div>
                  </a>
                </li>
                {/* end slide 02 */}
                {/* end slide 03 */}
                <li
                  data-index="rs-75"
                  data-transition="zoomout"
                  data-slotamount="default"
                  data-hideafterloop={0}
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed={1500}
                  data-thumb="http://works.themepunch.com/revolution_5_3/wp-content/"
                  data-rotate={0}
                  data-saveperformance="off"
                  data-title={3}
                  data-param1={3}
                  data-description
                >
                  {/* main image */}
                  <img
                    src={
                      require("../media/images/e-health/call-center.jpg")
                        .default
                    }
                    alt
                    data-bgcolor="#262b32"
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgrepeat="no-repeat"
                    data-bgparallax="off"
                    className="rev-slidebg"
                    data-no-retina
                  />
                  <div
                    className="overlay-bg bg-extra-dark-gray"
                    style={{ opacity: "0.5" }}
                  />
                  {/* main text layer */}
                  <div
                    className="tp-caption tp-resizeme alt-font text-white font-weight-600 text-center"
                    id="slide-411-layer-07"
                    data-frames='[{"delay":200,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-type="text"
                    data-whitespace="nowrap"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['-50','-50','-115','-65']"
                    data-width="auto"
                    data-height="auto"
                    data-fontsize="['70','53','60','35']"
                    data-lineheight="['70','59','70','39']"
                    data-letterspacing="['-2','-1','-1','-1']"
                    data-responsive="off"
                    data-responsive_offset="off"
                    data-paddingtop="['0','0','0','0']"
                    data-paddingbottom="['15','8','8','8']"
                    data-paddingright="['0','0','0','0']"
                    data-paddingleft="['0','0','0','0']"
                    style={{ textShadow: "0 0 20px rgba(0,0,0,0.3)" }}
                  >
                    24/7 Doctors
                    <br />
                    on Call Service
                  </div>
                  {/* small text layer */}
                  <div
                    className="tp-caption tp-resizeme alt-font text-dark font-weight-300 text-center"
                    id="slide-411-layer-08"
                    data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-type="text"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['65','100','-5','15']"
                    data-width="auto"
                    data-height="auto"
                    data-fontsize="['19','13','19','14']"
                    data-lineheight="['28','14','23','20']"
                    data-letterspacing="['0.5','0.5','0.5','0.5']"
                    data-responsive="off"
                    data-responsive_offset="on"
                  >
                    {/* From around the world in few minutes */}
                  </div>
                  {/* btn text-dark layer */}
                  <a
                    className="tp-caption tp-resizeme rs-btn btn btn-rounded d-flex text-light align-items-center justify-content-center"
                    href="/service/call-service#call-service"
                    id="slide-411-layer-09"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['152','130','82','80']"
                    data-whitespace="nowrap"
                    data-type="button"
                    data-responsive="off"
                    data-responsive_offset="off"
                    data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[-100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                    data-textalign="['center','center','center','center']"
                    data-paddingtop="['8','8','8','8']"
                    data-paddingbottom="['8','8','8','8']"
                    data-paddingright="['7','7','7','7']"
                    data-paddingleft="['34','34','34','34']"
                    style={{
                      backgroundColor: "#37514B",
                      color: "#fff",
                      fontSize: 15,
                    }}
                    target={"_blank"}
                  >
                    Get started now
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 38,
                        height: 38,
                        borderRadius: "50%",
                        background: "#fff",
                        marginLeft: 19,
                      }}
                    >
                      <i
                        className="fas fa-play"
                        style={{
                          color: "#37514B",
                          fontSize: 12,
                          lineHeight: 15,
                          marginTop: 2,
                          marginLeft: 3,
                        }}
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </article>
        <ContactPop room={""} price={500} />
      </section>
    </>
  );
};
