import React from "react";
import { Footer, TopNavigation } from "./Header";
import PageLoader from "./PageLoader";

export const Layout = ({
	boxheader,
	children,
	title,
	subtitle,
	bgimage,
	jumpto,
	_renderTitle,
	noOpacity,
}) => {
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, [])

	// if(loading) return <PageLoader/>
	return (
		<>
			<PageLoader loading={loading} />
			<TopNavigation boxheader={boxheader} />
			{boxheader && subtitle && (
				<section className="bg-extra-dark-gray padding-25px-tb page-title-small">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-12 col-xl-8 col-lg-6">
								{/* start page title */}
								<h1 className="alt-font text-white font-weight-500 no-margin-bottom text-center text-lg-left">
								{_renderTitle ? _renderTitle() : title}
								</h1>
								{/* end page title */}
							</div>
							<div className="col-12 col-xl-4 col-lg-6 breadcrumb justify-content-center justify-content-lg-end text-small alt-font md-margin-10px-top">
								{/* start breadcrumb */}
								<ul className="xs-text-center">
									<li>
										<a href="/" className="text-dark-hover">
											Home
										</a>
									</li>
									<li>
										<a href="#" className="text-dark-hover">
											{subtitle}
										</a>
									</li>
									{/* <li>Text box</li> */}
								</ul>
								{/* end breadcrumb */}
							</div>
						</div>
					</div>
				</section>
			)}
			{title && !boxheader && (
				<>
					<section
						className="parallax "
						data-parallax-background-ratio="0.5"
						style={{ backgroundImage: `url("${bgimage}")`, backgroundColor: "gray", }}
					>
						<div className={`${noOpacity ? "" : "opacity-extra-medium"} bg-extra-dark-gray`} />
						<div className="container">
							<div className="row align-items-stretch justify-content-center small-screen">
								<div className="col-12 col-xl-12 col-lg-12 col-md-12 page-title-extra-small text-center d-flex justify-content-center flex-column">
									<h1 className="alt-font text-white opacity-6 margin-20px-bottom">
										{subtitle}
									</h1>
									<h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
										{/* {title} */}
										{_renderTitle ? _renderTitle() : title}
									</h2>
								</div>
								{ title=="24/7 doctors on call service" ?
									<div class="tp-mask-wrap" style= {{
										display: "flex",
										overflow: "hidden",
										height: "fit-content",
										transform: "matrix(1, 0, 0, 1, 0, 0)"
										}}>
											<a style={{
												backgroundColor:"#37514B",
												color:"#ff",
												fontSize:"15px",
												visibility:"inherit",
												transition:"none 0s ease 0s",
												textAlign:"center",
												lineHeight:"22px",
												borderWidth:"1px",
												margin:"0px",
												padding:"8px 7px 8px 34px",
												letterSpacing:"0px",
												fontWeight:"500",
												whiteSpace:"nowrap",
												minHeight:"0px",
												minWidth:"0px",
												maxHeight:"none",
												maxWidth:"none",
												opacity:"1",
												transformOrigin:"50% 50% 0px",
												transform:"matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
												}}
												target="_blank" href="/service/call-service" className="tp-caption tp-resizeme rs-btn btn btn-rounded d-flex text-white align-items-center justify-content-center">
												Call us now
												<div style={{
													display:"flex",
													justifyContent:"center",
													alignItems:"center",
													width:"38px",
													height:"38px",
													borderRadius:"50%",
													background:"#fff",
													margin:"0px 0px 0px 19px",
													transition:"none 0s ease 0s",
													textAlign:"center",
													lineHeight:"22px",
													borderWidth:"0px",
													padding:"0px",
													letterSpacing:"0px",
													fontWeight:"500",
													fontSize:"15px",
												}}>
													<i class="feather icon-feather-phone-call  align-middle" style={{
														color:"#37514B",
														fontSize:"12px",
														lineHeight:"180px",
														margin:"2px 0px 0px 3px",
														transition:"none 0s ease 0s",
														textAlign:"inherit",
														borderWidth:"0px",
														padding:"0px",
														letterSpacing:"0px",
														fontWeight:"900",
													}}></i>
												</div>
											</a>
									</div> : <></>
								}
									{
										jumpto ? 
										<div className="down-section text-center">
											<a href={"#" + jumpto} className="section-link">
												<i className="ti-arrow-down icon-extra-small text-light bg-transparent-black padding-15px-all xs-padding-10px-all border-radius-100" />
											</a>
										</div> : <></>
									}
							</div>
						</div>
					</section>
				</>
			)}
			<div class="main-content">{children}</div>

			<Footer />
		</>
	);
};
