import React from "react";
import { Footer } from "../components/Header";
import { Layout } from "../components/Layout";
import BlogList from "./Blogs/BlogList";
import { ComingSoon } from "./ComingSoon";

export const Career = () => {
	return (
		<div className="bg-light-gray">
			<ComingSoon
				pageName="24Telemed"
				title={"Career at 24Telemed"}
			>
				<section className="bg-light-gray position-relative overflow-visible border-bottom border-color-medium-gray p-5" id="career">
			
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div
								className="col-12 col-md-10 text-center text-xl-start lg-margin-5-rem-bottom sm-margin-3-rem-bottom wow animate__fadeIn"
								style={{ visibility: "visible", animationName: "fadeIn" }}
							>
								<h6 className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px mb-0 d-inline-block lg-w-60 md-w-70 xs-w-100">
									24Telemed is looking for the best doctors to join our journey in providing quality healthcare to the community. Patient care remains our utmost priority.
								</h6>
							</div>
						</div>
					</div>
				</section>
		<form class="container bg-white p-3">
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="surname">Name-Surname</label>
						<input type="email" class="form-control" id="surname"/>
					</div>
					<div class="form-group col-md-6">
						<label for="phoneNo">Phone Number</label>
						<input type="phone" class="form-control" id="phoneNo"/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="date">Select Date</label>
						<div class="input-group">
							<input type="text" class="form-control" id="date" name="date" placeholder="DD/MM/YY" />
							<div class="input-group-addon">
								<i class="fa fa-calendar"></i>
							</div>
						</div>
					</div>
					<div class="form-group col-md-6 custom-file">
						<label>Upload your CV</label>
						<div class="input-group">
							<label class="h-auto form-control" for="cv">Upload your CV</label>
							<div class="input-group-addon">
								<i class="fa fa-file"></i>
							</div>
						</div>
						<input type="file" class="custom-file-input" id="cv"/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-12">
						<label for="email">Email</label>
						<input type="text" class="form-control" id="email"/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-12">
						<label for="addMessage">Additional Message</label>
						<textarea type="text" class="form-control" id="inputCity"/>
					</div>
				</div>
                <button type="submit" class="w-100 btn btn-primary">SEND</button>
			</form>
			{/* <Footer /> */}
			</ComingSoon>
			<div style={{
				height: "30px"
			}}></div>
		</div>
	);
};
