import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { About } from "./pages/About";
import { About2ndOpinion } from "./pages/About2ndOpinion";
import BlogPage from "./pages/Blogs/BlogPage";
import { BlogViewPage } from "./pages/Blogs/BlogViewPage";
import { Career } from "./pages/Career";
import { Contact } from "./pages/Contact";
import { Gallery } from "./pages/Gallery";
import { Home } from "./pages/HomeInterior";
import { CallCenter } from "./pages/CallCenter";
import { Landing } from "./pages/Landing";
import { NotFound } from "./pages/NotFound";
import { OrdinaryPage } from "./pages/OrdinaryPage";
import { ProductView } from "./pages/ProductView";
import { Rooms } from "./pages/Rooms";
import { ServicePage } from "./pages/ServicePage";
import { Services } from "./pages/Services";
import { PharmPage } from "./pages/PharmPage";
import { FAQ } from "./pages/FAQ";
import { DiagnosticPage } from "./pages/DiagnosticPage";

function App() {
  const supportsHistory = "pushState" in window.history;
  return (
    <Router forceRefresh={!supportsHistory}>
      <Switch>
        <Route exact path="/home" component={Home} />
        <Route exact path="/" component={Home} />
        <Route exact path="/career" component={Career} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/call-center" component={CallCenter} />
        <Route exact path="/solutions" component={Services} />
        <Route exact path="/about" component={About2ndOpinion} />
        <Route exact path="/rooms" component={Rooms} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/service/-opinion/:title" component={ProductView} />
        <Route exact path="/service/:title" component={ServicePage} />
        <Route exact path="/p/:title" component={OrdinaryPage} />
        <Route exact path="/blogs" component={BlogPage} />
        <Route exact path="/blogs/:post_title" component={BlogViewPage} />
        <Route
          exact
          path="/blogs/:post_id/:post_slug"
          component={BlogViewPage}
        />
        <Route exact path="/faq" component={FAQ} />
        {/* <Route exact path="/pharmaceutical-services" component={PharmPage} />
        <Route exact path="/diagnostic-services" component={DiagnosticPage} /> */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
