import React from 'react'
import { Layout } from '../../components/Layout'
import BlogList from './BlogList'
import BlogSection from './BlogSection'

const BlogPage = () => {
    return (
        <Layout 
        subtitle={"Get updated"}
			title={"Our Blog"}
            bgimage={"https://images.pexels.com/photos/4458/cup-mug-desk-office.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}
        // boxheader={"navbar navbar-expand-lg navbar-light bg-white header-light fixed-top header-reverse-scroll menu-logo-center box-shadow-extra-large h-80"}
        >
            <BlogList />
        </Layout>
    )
}

export default BlogPage
