import React from "react";
import { apiGet } from "../../api/connect";
import { Layout } from "../../components/Layout";
import { formatDate } from "./BlogList";
import PostAuthor from "./PostAuthor";
import RelatedPost from "./RelatedPost";

export const BlogViewPage = ({ match }) => {
	const [post, setPost] = React.useState();
	const [user, setUser] = React.useState();
	const [media, setMedia] = React.useState();
	const [categories, setCagories] = React.useState([]);

	React.useEffect(() => {
		const { post_id, post_slug } = match?.params;

		if (post_id > 0) {
			apiGet({}, "/posts/" + post_id).then((res) => {
				setPost(res);

				apiGet({}, "/media/" + res?.featured_media).then((res) => {
					setMedia(res?.guid?.rendered);
				});

				apiGet({}, "/categories?post=" + res?.id).then((res) => {
					setCagories(res);
				});
				
                apiGet({}, "/users/" + res?.author).then((res) => {
					setUser(res);
				});
			});
		}
	}, []);

	return (
		<Layout>
			<section
				className="h-700px sm-h-400px xs-h-300px overlap-height cover-background"
				style={{ background: `url(${media || "https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-6.jpg"})` }}
			></section>

			<section className="pb-0 pt-md-0 overflow-visible">
				<div className="container">
					<div
						className="row justify-content-center overlap-section z-index-0"
						style={{ marginTop: "-205.1px" }}
					>
						<div
							className="col-12 col-lg-10 alt-font text-center bg-orange-zest text-dark padding-7-rem-tb position-relative tilt-box"
							data-tilt-options='{ "maxTilt": 20, "perspective": 1000, "easing": "cubic-bezier(.03,.98,.52,.99)", "scale": 1, "speed": 500, "transition": true, "reset": true, "glare": false, "maxGlare": 1 }'
							style={{
								backgroundImage:
									`url("../../images/blog-post-layout-02-img-pattern.png")`,
								willChange: "transform",
								transform: "perspective(1000px) rotateX(0deg) rotateY(0deg)",
                                borderRadius: 10
							}}
						>
							<div className="w-1px h-90px bg-white mx-auto absolute-middle-center top-0px" />
							<div className="text-uppercase text-medium font-weight-500 margin-25px-bottom alt-font">
								{categories?.map((cat, i) => {
                                    return (
                                        <>
                                        <a href="blog-grid.html" className="text-dark d-inline-block">
									{cat?.name}
								</a>
                                {i < categories?.length - 1 && (
                                    <span class="margin-20px-lr w-1px h-10px bg-white d-inline-block"></span>
                                )}
                                </>
                                    )
                                })}
							</div>
							<h3 className="font-weight-600 w-50 mx-auto md-w-70 xs-w-90">
								{post?.title?.rendered}
							</h3>
							<div className="d-inline-block text-dark-transparent margin-5px-top">
								By{" "}
								<a
									href="blog-grid.html"
									className="d-inline-block text-dark-transparent text-dark-hover"
								>
									24Telemed
								</a>{" "}
								on{" "}
								<a
									href="#"
									className="d-inline-block text-dark-transparent text-dark-hover"
								>
									{formatDate(post?.date)}
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="small-section">
				<div className="container blog">
					<div className="row justify-content-center">
						<div className="col-12 col-lg-10 p-sm-0">
							<div className="row align-items-center"
                                dangerouslySetInnerHTML={{ __html: post?.content?.rendered }}
                            >
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <PostAuthor  
                categories={categories}
                user={user}
                 /> */}
			{/* <RelatedPost /> */}
		</Layout>
	);
};
