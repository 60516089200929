import React from "react";
import { Layout } from "../components/Layout";
import { PrivacyPolicy } from "../data/staticpage/PrivacyPolicy";

export const OrdinaryPage = ({ match }) => {
	const [page, setPage] = React.useState({});

	React.useEffect(() => {
		const _page = require("../data/staticpage/pages.json");
		const { title } = match?.params;
		let data = _page[title];
		data.content = <PrivacyPolicy {...data} />
		setPage(data);
	}, [match])

	return (
		<Layout
			subtitle={page?.title}
			title={page?.subtitle}
			boxheader={`navbar top-space navbar-expand-lg navbar-light bg-white header-light fixed-top header-reverse-scroll navbar-boxed`}
		>
			<section className="blog-right-side-bar">
				<div className="container">
					<div className="col-12 blog-details-text last-paragraph-no-margin margin-6-rem-bottom"
					>
						{page.content}
					</div>
				</div>
			</section>
		</Layout>
	);
};
