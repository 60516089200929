import React from "react";
import { Footer, TopNavigation } from "./Header";
import PageLoader from "./PageLoader";
export const Accordion = ({
	title,
    detail,
    index
})=>{
    return (
<div className="row justify-content-center">
                    {/* <div className="col-12 col-md-10"> */}
                        <div className="w-100 panel-group accordion-event accordion-style-03" id={`accordion${index}`} data-active-icon="fa-angle-down" data-inactive-icon="fa-angle-right">
                            <div className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn" data-wow-delay="0.6s" style={{visibility: "visible", "animation-delay": "0.6s", "animation-name": "fadeIn"}}>
                                <div className="panel-heading">
                                    <a className="accordion-toggle collapsed" data-bs-toggle="collapse" data-bs-parent={`#accordion${index}` } href={`#accordion-style-0${index}-0${index}`} aria-expanded="false">
                                        <div className="panel-title">
                                            <span className="alt-font text-extra-dark-gray d-inline-block font-weight-500">{title}</span>
                                            <i className="indicator fas fa-angle-right text-extra-dark-gray icon-extra-small"></i>
                                        </div>
                                    </a>
                                </div>
                                <div id={`accordion-style-0${index}-0${index}`} className="panel-collapse collapse" data-bs-parent={`#accordion${index}`}>
                                    <div className="panel-body" dangerouslySetInnerHTML={{__html: detail}}></div>
                                </div>
                            </div>
                            
                        </div>
                    {/* </div> */}
                </div>
    )}