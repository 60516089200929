/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PharmPartners, RadioPartners, SuppliesPartners } from "./constants";
import { Layout } from "../components/Layout";
import { services2 } from "../data/services";
import { Home2ndOpinion } from "./Home2ndOpinion";
import { CallCenterService } from "./CallCenterService";
import HealthDataPage from "./HealthDataPage";
import { Gallery } from "./CprGallery";
import PartnerWithCarousel from "../components/PartnerWithCarousel";

const dict = {"laboratory-&-radiology-services": RadioPartners, "pharmaceutical-services": PharmPartners, "medical-equipments-&-medicine-delivery-services": SuppliesPartners}
const titles = {"laboratory-&-radiology-services": "Our Partners", "pharmaceutical-services": "Our Partners", "medical-equipments-&-medicine-delivery-services": "Our Partners"}

export const ServicePage = ({ match }) => {
	// const [title, setTitle] = React.useState();
    const [data, setData] = React.useState({
        intro: "24Telemed"
    })
	const title = window.location.href.split(["/service/"])[1];
    React.useEffect(() => {
        const { title  } = match?.params;
        const service = services2.find(e => e?.link?.toString().includes(title))
        if(service){
			setData({...data, ...service})
        }else{
            window.location  = "/page-not-found";
        }
    }, []);
	
	const d = [
		{
			title: "eclinic-platform",
			message: '<h5 class="alt-font text-dark font-weight-300 m-0">Are you ready to grow your <span class="font-weight-600">Medical Practice Virtually?</span></h5>'
		},
		{
			title: "home-services",
			message: '<h5 class="alt-font text-dark font-weight-300 m-0">Nurses at the comfort of  <span class="font-weight-600">your home</span></h5>'
		},
	]
	const calltoAction = (x) => {
		return d.find(e => e.title === x)
	}
	return (
		<Layout
		// boxheader={`navbar top-space navbar-expand-lg navbar-light bg-white header-light fixed-top header-reverse-scroll navbar-boxed`}
		>
			<div>
				<section
					className="p-0 cover-background overlap-height"
					style={{
						backgroundImage: `url("${data?.image}")`,
					}}
				>
					<div className="opacity-very-light bg-dark-slate-blue" />
                    {/* <div className="overlay-bg bg-gradient-dark-slate-blue-transparent top-bottom" /> */}
					<div className="container position-relative">
						<div className="row full-screen md-h-800px sm-h-500px">
							<div className="col-12 col-xl-12 col-lg-6 col-md-7 col-sm-10 d-flex flex-column justify-content-center">
								<h2 className="alt-font text-white line-height-65px font-weight-500 letter-spacing-minus-1px margin-65px-bottom sm-line-height-50px sm-margin-25px-bottom"
								style={{
									wordBreak: "normal"
								}}
								>
									{data?.name}
								</h2>
								<div className="alt-font text-medium font-weight-500 text-uppercase letter-spacing-2px d-flex">
									<span className="w-40px h-1px bg-iris-blue align-self-center margin-25px-right" />
									<span className="text-iris-blue align-self-center">
										{data?.intro}
									</span>
								</div>
							</div>
						</div>
					</div>
					{calltoAction(title) && (
						<div className="d-flex flex-row justify-content-end ms-auto w-750px position-absolute right-0px bottom-0px z-index-1 sm-position-relative sm-w-100">
							<div className="row align-items-center justify-content-end mx-0 w-100">
								<div className="col-12 col-sm-9 align-items-center d-flex bg-iris-blue h-100 padding-3-rem-tb padding-5-rem-lr xs-padding-3-rem-lr"
									dangerouslySetInnerHTML={{ __html: calltoAction(title).message }}
								/>
								<div className="col-3 p-0 h-100 d-none d-xl-inline-block">
									<a
										href="https://econsult.24telemed.org/"
										className="text-center align-items-center d-flex justify-content-center bg-seashell h-100"
									>
										<i className="line-icon-Arrow-OutRight icon-extra-large text-iris-blue" />
									</a>
								</div>
							</div>
						</div>
					)}
				</section>
				{/* end hero section */}
				{/* start section */}
				<section
					className="home-consulting big-section cover-background"
					style={{
						backgroundImage: 'url("../images/home-consulting-about-bg.jpg")',
					}}
					{...(data?.name=="24/7 doctors on call service" ? {id: 'call-service'} : {}) }
				>
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className={`col-12 ${data?.fullwidthContent || "col-lg-6"} col-md-10 md-margin-5-rem-bottom wow animate__fadeIn`}>
								<h4 className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-4-rem-bottom w-80 lg-w-90 md-margin-3-rem-bottom xs-margin-4-rem-bottom xs-w-100">
									{/* Evoke eHealth{" "}<br/> */}
									<span className="text-iris-blue text-uppercase  font-weight-600">
										{data?.name}
									</span>
								</h4>
								<p className="w-70 margin-40px-bottom lg-w-90 md-margin-25px-bottom"
                                dangerouslySetInnerHTML={{__html: data?.description }}
                                >
								</p>
							</div>
							{!data?.fullwidthContent && (
							<div
								className="col-12 col-lg-5 offset-lg-1 col-md-10 wow animate__fadeIn"
								data-wow-delay="0.2s"
							>
								<div className="position-relative">
									<div className="opacity-very-light bg-dark-slate-blue" style={{ borderRadius: 10 }} />
									<img
										className="w-100"
										src={data?.image}
										alt
                                        style={{ borderRadius: 10 }}
									/>
								</div>
							</div>
							)}
						</div>
					</div>
				</section>
			</div>
			{/* partners */}
			{/* { dict[title] && <PartnerWithCarousel title={titles[title]} partners={dict[title]}/> } */}
			{/* { title=="cardiopulmonary-resuscitation-training" && <Gallery match={"cpr"}/> } */}
            { data?.name === "2nd medical opinion" && <Home2ndOpinion /> }
			{ data?.name === "24/7 doctors on call service" && <CallCenterService /> }
			{/* { data?.name === "Health data page" && <HealthDataPage /> } */}
			
			{/* <CallToActionSP /> */}

		
		</Layout>
	);
};

export const CallToActionSP = ({ children }) => {
	return (
		<section
		className="big-section parallax wow animate__fadeIn"
		data-parallax-background-ratio="0.5"
		// style={{ visibility: "visible", animationName: "fadeIn" }}
		style={{backgroundImage: `url("${require("../media/images/e-health/bg-green-top.png").default}")`, visibility: 'visible', animationName: 'fadeIn', backgroundPosition: '50% -95.475px'}}
		
	>
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-lgxl-7 col-lg-8 text-center">
					{children ||(
						<>
						<h2 className="alt-font font-weight-300 text-dark text-extra-dark-gray margin-4-rem-bottom letter-spacing-minus-2px">
						24Telemed <br/><span className="font-weight-600">make a world of difference.</span> 
						
					</h2>
					<div className="btn text-dark-dual">
						<a
							href="/contact"
							className="btn text-dark btn text-dark-extra-large btn text-dark-transparent-fast-blue  margin-25px-right xs-margin-15px-bottom"
						>
							Contact us now
						</a>
					</div>
						</>
					)}
				</div>
			</div>
		</div>
	</section>
	)
}

// export const addPartner = <TeamWithCarousel title='Our Partners' partners={PharmPartners} />
// export const addPartner = (<section
// 			className="cover-background wow animate__fadeIn"
// 			style={{
// 				backgroundImage: `url("${
// 					require("../media/img/base-1.png").default
// 				}")`,
// 				visibility: "visible",
// 				animationName: "fadeIn",
// 			}}
// 			>
// 			<div className="container">
// 				<div className="row justify-content-center">
// 				</div>
// 				<div className="row row-cols-1 row-cols-lg-5 row-cols-sm-2 margin-8-half-rem-top client-logo-style-07 lg-margin-5-rem-top xs-no-margin-top">
// 					{/* start client logo item */}
// 					{partners?.map((item, i) => {
// 						return (
// 							<div
// 								className="col text-center client-logo md-margin-30px-bottom xs-margin-25px-bottom wow animate__fadeIn"
// 								data-wow-delay="0.6s"
// 								style={{
// 									visibility: "visible",
// 									animationDelay: "0.6s",
// 									animationName: "fadeIn",
// 								}}
// 							>
// 								<a href={item.link}>
// 									<img alt="" src={item?.logo} data-no-retina />
// 								</a>
// 							</div>
// 						);
// 					})}
// 				</div>
// 			</div>
// 			</section>
// 	)