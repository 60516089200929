import React from 'react'

export const AfterSlider = () => {
    return (
<section id={"start-now"} className="cover-background overflow-visible" >
  <div className="container">
    <div className="row" style={{
      marginTop: "80px"
    }}>
      <div className="col-12 overlap-section md-no-margin-top md-margin-70px-bottom sm-margin-50px-bottom" style={{marginTop: '-270.5px'}}>
        <div className="row justify-content-center">
        <div className="col-12 col-lg-4 col-md-6 col-sm-8 md-margin-30px-bottom xs-margin-15px-bottom">
            {/* start feature box item*/}
            <div className="feature-box h-100 feature-box-left-icon-middle padding-3-rem-all bg-white box-shadow-small box-shadow-large-hover border-radius-4px overflow-hidden last-paragraph-no-margin lg-padding-2-half-rem-tb lg-padding-2-rem-lr md-padding-4-rem-all">
              <div className="feature-box-icon margin-30px-right lg-margin-25px-right">
                <i className="line-icon-Credit-Card2  icon-medium text-iris-blue" />
              </div>
              <div className="feature-box-content">
              <div className="text-slate-blue font-weight-500 text-large margin-5px-bottom">Affordable prices</div>
                <span>We believe cost shouldn’t be a barrier to healthcare. That’s why 24Telemed services are accessible to all.  </span>
              </div>
            </div>
            {/* end feature box item*/}
          </div>
          <div className="col-12 col-lg-4 col-md-6 col-sm-8">
            {/* start feature box item*/}
            <div className="feature-box h-100 feature-box-left-icon-middle padding-3-rem-all bg-white box-shadow-small box-shadow-large-hover border-radius-4px overflow-hidden last-paragraph-no-margin lg-padding-2-half-rem-tb lg-padding-2-rem-lr md-padding-4-rem-all">
              <div className="feature-box-icon margin-30px-right lg-margin-25px-right">
                <i className="line-icon-Geo- icon-medium text-iris-blue" />
              </div>
              <div className="feature-box-content">
                <div className="text-slate-blue font-weight-500 text-large margin-5px-bottom">Rural areas</div>
                <span>24Telemed creates a robust telecommunication and virtual practice to treat patients.
                            <br/>Our qualified and experienced physicians are ready to manage your symptoms.</span>
              </div>
            </div>
            {/* end feature box item*/}
          </div>
          <div className="col-12 col-lg-4 col-md-6 col-sm-8 md-margin-30px-bottom xs-margin-15px-bottom">
            {/* start feature box item*/}
            <div className="feature-box h-100 feature-box-left-icon-middle padding-3-rem-all bg-white box-shadow-small box-shadow-large-hover border-radius-4px overflow-hidden last-paragraph-no-margin lg-padding-2-half-rem-tb lg-padding-2-rem-lr md-padding-4-rem-all">
              <div className="feature-box-icon margin-30px-right lg-margin-25px-right">
                <i className="line-icon-Timer icon-medium text-iris-blue" />
              </div>
              <div className="feature-box-content">
                <div className="text-slate-blue font-weight-500 text-large margin-5px-bottom">24 Hours service</div>
                <span>With accessibility in mind, we have made 24Telemed services extremely simple and easy to use for both patients and clinicians. </span>
              </div>
            </div>
            {/* end feature box item*/}
          </div>          
        </div>
      </div>
    </div>
  </div>
</section>

    )
}

// export const CallAfterSlider = () => {
//   return (
//     <section id={"start-now"} className="extra-big-section cover-background overflow-visible" style={{backgroundImage: 'url("images/home-startup-about-bg.jpg")'}}>
//     <div className="container">
//       <div className="row align-items-end d-block text-center">
//         <div className="d-inline-block col-12 col-lg-8 col-md-6 order-1 text-center text-lg-end last-paragraph-no-margin md-margin-50px-bottom wow animate__fadeInLeft" style={{visibility: 'visible', animationName: 'fadeInLeft'}}>
//           <h6 className="w-90 d-inline-block sm-w-60 xs-w-80 text-extra-dark-gray font-weight-600">Call our team of qualified and experienced 24/7 doctors on call for immediate medical questions and management wherever you are and at your convenience.<br/><br/>
//                   If your condition can not be managed over the phone, doctors on call will either refer you to the nearest hospital in your area or assist you with scheduling an appointment to speak a doctor specialized in the taking care of your medical condition. 
//           {/* We connect with patients using the latest video conferencing security and privacy standards, Cost effective solutions and integrated billing. */}
//           </h6>
//         </div>
//     </div>
//     </div>
//     </section>
//   )
// }