import React from 'react';
import { services } from "../data/services";

export const CallCenterService = () => {
    const source = require("../data/call-center-service.json");
	const data = source["callService"];
    return (
        <>
            <div className="home-consulting big-section cover-background"
					style={{
						backgroundColor: '#faf6f3',
                        marginBottom: '20px'
					}}>
                <div class="d-flex justify-content-around" 
					style={{
                        marginBottom: '20px'
					}}                
                >
                    {Object.entries(data).map((a, b) =>
                        <div className="text-center" style={{
                            width: "15%"
                        }}>
                            <img
                                src={require(`../media/images/${a[1]}`).default}
                            />
                            <div style={{
                                color: "#000",
                                fontSize: "15px",
                                marginTop: "5px"
                            }}>{a[0]}</div>
                        </div>
                    )}            
                </div>
            </div>
        {/* <br/><br/> */}
        </>
    )
}
