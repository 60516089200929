import axios from "axios";

const apibase = "https://theboolean.tech/evokeblog/wp-json/wp/v2";

export const getHeaders = () => {
	let headers = {
		"Content-Type": "application/json",
		Accept: "*/*",
	};

	return { headers };
};

export const apiGet = (data, endpoint) => {
	return axios
		.get(`${apibase+endpoint}${new URLSearchParams(data).toString()}`, getHeaders(), {})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err?.response;
		});
};