/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

export const PrivacyPolicy = ({ dirname }) => {
  return (
    <div className="paper">
      <iframe
        src={dirname}
        style={{
          width: "100%",
          height: 1000,
          borderRadius: 15,
        }}
      />
    </div>
  );
};

export const PrivacyPolicy2 = () => {
  return (
    <div className="paper">
      <p className="c4">
        <span className="c3">Terms Of Use &amp; Privacy Policy </span>
      </p>
      <p className="c4">
        <span className="c8">
          Welcome to our website where you can request a remote second medical
          opinion. Effective Date: 01 January, 2022 NOTE: 24Telemed Expert Medical/Second Opinion is Not intended to address
          emergency or life-threatening medical conditions. If this is an
          Emergency, please visit your nearest hospital or health center. This
          Agreement ("Agreement") and the policies referred to herein contain
          the terms and conditions that apply to your use of the 24Telemed Internet Web Site and services located
          at &nbsp;
        </span>
        <span className="c7 c11">
          <a
            className="c12"
            href="https://www.google.com/url?q=https://www.24telemed.org/&sa=D&source=editors&ust=1633087194156000&usg=AOvVaw32N89f-RtO97qnj-RLFLql"
          >
            https://www.24telemed.org/
          </a>
        </span>
        <span className="c7">&nbsp;</span>
        <span className="c1">
          ("Web Site, Website, Site, site") and All affiliated websites Owned,
          Operated, Licensed or Controlled by 24Telemed (“Company”).{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">
          PLEASE READ THIS AGREEMENT CAREFULLY. THIS AGREEMENT GOVERNS YOUR USE
          OF THE WEB SITE. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT OR
          ANY REVISED VERSION OF THIS AGREEMENT, PLEASE DO NOT USE THIS WEB SITE
        </span>
        <span className="c8">
          . Your access and use of the Web Site creates a binding and
          enforceable legal agreement between you, the Web Site user and Company
          and it signifies your acceptance and consent to be bound by the terms
          and conditions contained in this Agreement. We may, in our sole
          discretion, change these terms of use and conditions at any time by
          updating the web page. These changes are binding on you, and it is
          your responsibility to review these terms and conditions on a regular
          basis. You acknowledge that Company, 24Telemed, operates a web based portal used by individuals to
          access FOREIGN based physicians to obtain Limited second medical
          opinion and telephonic/ video meeting consultations.{" "}
        </span>
        <span className="c3">
          BY USING THE SITE, ANY OR ALL of the SERVICES PROVIDED ON THE SITE
          AND/OR BY REGISTERING WITH US, YOU SIGNIFY THAT YOU AGREE TO THESE
          TERMS OF USE AND PRIVACY POLICY
        </span>
        <span className="c1">
          . Please note that we offer the Site "AS IS" and without warranties.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          About the Site. Everything we offer on the Web Site is referred to in
          these Terms of Use collectively as the "Services." Some of what is on
          the Site is viewable without registering with us, but to actively
          participate or store your information, you must register as a member
          and authorize the use and disclosure of your personal and health
          information for purposes of allowing us to provide the Services and as
          otherwise disclosed in our Privacy Policy below. You acknowledge that
          although some of the content, text, data, graphics, images,
          information, suggestions, guidance, and other materials (collectively,
          "Information") that is provided to you on the Site (including
          Information provided in direct response to your questions or postings)
          may be provided by individuals in the medical profession, the
          provision of such Information does not create a medical
          professional/patient relationship, and does not constitute an opinion,
          medical advice, or diagnosis or treatment of any particular condition,
          but is provided to assist you with locating appropriate medical care
          from a qualified practitioner.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">DISCLOSURE OF MEDICAL INFORMATION.</span>
        <span className="c1">
          &nbsp;Your privacy is important to us. The Company takes seriously the
          privacy of your medical records and personal information. Accordingly,
          we have taken all reasonable restrictions necessary to protect your
          confidentiality in accordance with the Nigeria Data Protection
          regulation 2019 and other applicable laws. In addition, you authorize
          the Company and their respective physicians, employees,
          contractors,processors, business associates and/or agents to access,
          review, research, analyze, discuss and copy your medical and personal
          information as may be necessary to provide the SPECIALIZED FOREIGN
          Second Expert Medical Opinion that you request. The use of your
          medical information will not exceed this stated purpose, unless and
          until you authorize and direct such additional disclosure(s).{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">NO DOCTOR PATIENT RELATIONSHIP.</span>
        <span className="c1">
          &nbsp;NO LICENSED MEDICAL PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED
          BY USING INFORMATION PROVIDED BY OR THROUGH THE USE OF THE SITE, LINKS
          TO OTHER SITES OR ANY ASSISTANCE WE MAY PROVIDE TO HELP YOU FIND AN
          APPROPRIATE MEDICAL PROFESSIONAL OR SPECIALIST IN ANY FIELD. You
          acknowledge and agree that the Company’s, Evoke Ehealth Solutions
          Limited, reviews, telephone discussions and/or telephonic video
          meetings should not in any form, shape or fashion constitute a
          diagnosis, medical advice, treatment, medical care or establish any
          doctor-patient relationship. Furthermore, you understand that your
          treating physician(s) will remain at all times solely responsible for
          your diagnosis, care, treatment and evaluation noted in the Anambra
          State e-Health Services care Limited review provided. Your doctor
          shall directly communicate to you all the information contained in the
          Anambra State e-Health Services care Limited Review. Please read the
          informed consent section below for further details.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c0">PAYMENT OF FEES. </span>
        <span className="c8 c10">
          You acknowledge that you have read and reviewed the Fee Schedule as
          posted on the website in the Payment section. You acknowledge that you
          or any authorized individual acting on your behalf and who is
          requesting Specialized/Foreign Second Opinion Review and/or video
          meetings are responsible for all fees charged by Company, Evoke
          Ehealth Solutions Limited, for the services hereunder (including, but
          not limited to, fees for professional services). You understand that
          you or your authorized agent must pay these fees in advance, and that
          it is your responsibility to pursue any third-party insurance
          reimbursement at your own expense, if any. You have been informed that
          you have the option to withdraw consent for this Specialized/Foreign
          Second Opinion Review at any time. However, if you withdraw your
          consent after payment is made, you will remain responsible for the
          full amount. No money will be refunded after services are issued. You
          also authorize Company, Evoke Ehealth Solutions Limited to pursue and
          collect any further charges for time extension of video meetings
          beyond the allocated initial 15 minutes based on the posted fee
          schedule and Video meeting section.
        </span>
      </p>
      <p className="c4">
        <span className="c3">RELEASE OF LIABILITY</span>
        <span className="c1">
          . You further release and hold harmless the Company, Evoke Ehealth
          Solutions Limited, and its/their respective physicians, employees,
          processors,contractors, business associates and/or agents for any
          harm, claim, injury or damages of any kind including, but not limited
          to, compensatory, direct, indirect or consequential damages, directly
          or indirectly, as a result of any and all uses of the services
          hereunder and any review, interpretation or analysis and/or reliance
          on any and all Specialized/Foreign Expert Medical Second Opinion/
          Review. Although every effort will be made to generate a
          Specialized/Foreign Expert Medical Opinion/ Review report in
          reasonably timely fashion, the Company, 24Telemed Limited makes no representation or warranty as to the
          time frame in which such Specialized/Foreign Expert Second Medical
          Opinion/ Review or consultation report will be generated.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">RESTRICTIONS ON USE.</span>
        <span className="c1">
          &nbsp;Unless otherwise noted, all materials, including images,
          illustrations, designs, icons, photographs, video clips and writings
          and other materials that are part of Company or Company's Website(s)
          are under copyrights, trademarks, trade dress and/or other
          intellectual property and are owned, controlled or licensed by
          Company. No material from Company's Website(s) or any other Company
          web site may be copied, reproduced, republished, uploaded, posted,
          transmitted or distributed in any way, except with the express written
          consent of Company. In addition, you shall not commit any of the
          following acts through use of Company's Website(s): a. transmit any
          content that is invasive of another's privacy or otherwise
          objectionable; b. use foul language or post links to adult-oriented
          content; c. transmit any unsolicited or unauthorized advertising,
          promotional materials, "junk mail," "spam," "chain letters," "pyramid
          schemes" or any other form of unrequested solicitation; d. post
          advertising messages or solicitations, URLs containing a referral code
          or referral address, or links to businesses or pages with advertising,
          including "blind" or "hidden" referral links; or e. collect, store,
          use or disseminate personal data or information about other users in
          any manner whatsoever, including but not limited to, solicitation of
          products or services by mail, telephone or email (e.g. email
          addresses).{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">LICENSE</span>
        <span className="c1">
          . The Company, Anambra State e-Health Services care Limited, grants
          you a limited license to access and make personal use of its Hosting
          platform,or other hosting services used by Company to host its
          websites, and not to download or modify it, or any portion of it. This
          license does not include any resale or commercial use of any of the
          hosting company’s contents; any collection and use of any
          advertisements, descriptions or prices; any derivative use of hosting
          companies, 24TELEMED LIMITED or its contents; any downloading or
          copying of account information for the benefit of another merchant; or
          any use of data mining, robots, or similar automated data gathering
          and extraction tools. You may not frame or utilize framing techniques
          to enclose any trademark, logo or other proprietary information
          (including images, text, page layout or form) of Company or our
          affiliates without express written consent. You may not use any meta
          tags or any other “hidden text” utilizing Company’s name or trademarks
          without the express written consent of Company. Any unauthorized use
          terminates the permission or license granted by the Company. You are
          granted a limited, revocable, and nonexclusive right to create a
          hyperlink to the home page of Company so long as the link does not
          portray Company, its affiliates, or their products or services in a
          false, misleading, derogatory or otherwise offensive manner. You may
          not use any Company logo or other proprietary graphic or trademark as
          part of the link without express written permission. To the extent
          that Company’s sites contain links to outside services and resources,
          Company does not control the availability and content of those outside
          services and resources. Any concerns regarding any such service or
          resource, or any link thereto, should be directed to the particular
          service or resource.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">VIOLATIONS</span>
        <span className="c1">
          . In addition to any and all remedies at law or in equity, any
          intentional violation of this Agreement shall give Company the right
          to immediately suspend or cancel its services to, and business
          relationship with you.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">CHANGES TO POLICIES AND PROCEDURES</span>
        <span className="c1">
          . The Company reserves the right at any time, with or without notice,
          to change, alter, modify or delete any of its policies, procedures,
          terms of service, website content, fees and/or notices. Please check
          this Agreement periodically for changes. Continued use of the Web Site
          following the posting of changes to this Agreement shall mean you have
          read and accepted such changes.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          EDITORIAL CONTENT. We make the Site available as a service to
          consumers and health care professionals for the purposes of providing
          an informative and educational resource. We may, but have no
          obligation to, have non-user posted information reviewed by the Site's
          editorial personnel. It is important to note, however, that the
          timeliness and accuracy of any or all of the Information is not
          guaranteed. Neither the authors, the editorial personnel, nor any
          other party who has been involved in the preparation or publication of
          this work can assure you that the Information contained herein is in
          every respect accurate or complete, and they are not responsible for
          any errors or omissions or for the results obtained from the use of
          such information. You are encouraged to independently confirm the
          Information contained herein with other sources and to seek the advice
          of a qualified physician.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">PROCEDURES / PRODUCTS / SERVICES.</span>
        <span className="c1">
          &nbsp;The procedures, products, services and devices discussed and/or
          advertised within the Site are not applicable to all individuals,
          patients or all clinical situations. We make no claims as to the
          effectiveness of any such procedures, products, services and devices.
          Any products and/or services represented on the Site by advertisers,
          sponsors, and other Site participants, either paid or unpaid, are
          presented for your awareness and do not necessarily imply
          appropriateness for any particular individual, endorsement or
          prediction of effectiveness, outcome or success.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">YOUR PERSONAL INFORMATION</span>
        <span className="c8">.</span>
        <span className="c1">
          &nbsp;In order to register, you must provide certain personal and
          medical information about yourself. You may voluntarily submit, and
          hereby authorize the Company, Anambra State e-Health Services care
          Limited, its employees, agents and others operating on its behalf, to
          use and/or disclose personal and health-related information about you,
          including, without limitation, your name, address, National
          Identification Number(NIN), Bank Verification Number(BVN) and contact
          information; medical and social history; current medical needs; and
          other information that is either requested by the Company, Anambra
          State e-Health Services care Limited or volunteered by you ("Personal
          Information"). You have the right to refuse to authorize the use
          and/or disclosure of your Personal Information.However if you choose
          to exercise this right, you may not be able to utilize some features
          of the Site.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">YOUR RESPONSIBILITIES.</span>
        <span className="c1">
          &nbsp;Even though the Services are provided free of charge, the usual
          and customary charges for any medical services rendered by
          collaborating physicians profiled on the Site will apply and will be
          entirely your responsibility. The fees for the services are available
          in the Payment section. You are responsible for all use of the Site
          and for all use of your Credentials, including use by others to whom
          you have given your Credentials. You may use the Site and the Services
          for lawful, non-commercial purposes only. You may not use the Site in
          any manner that could damage, disable, overburden, or impair our
          servers or networks, or interfere with any other party's use and
          enjoyment of the Site or the Services. You may not attempt to gain
          unauthorized access to any of the Services, user accounts, or computer
          systems or networks, through hacking, password mining or any other
          means. Without limiting any of the foregoing, you agree that you shall
          not (and you agree not to allow any third party to): • copy, modify,
          adapt, translate, or reverse engineer any portion of the Site, its
          content or materials and/or the Services; • remove any copyright,
          trademark or other proprietary rights/notices contained in or on the
          Site and/or the Services or in or on any content or other material
          obtained via the Site and/or the Services; • use any robot, spider,
          site search/retrieval application, or other automated device, process
          or means to access, retrieve or index any portion of the Site and/or
          the Services; • access, retrieve or index any portion of the Site
          and/or the Services for purposes of constructing or populating a
          searchable database of reviews related to the healthcare industry or
          medical physicians; • reformat or frame any portion of the web pages
          that are part of the Site and/or the Services; • fraudulently misuse
          the Services by scheduling an appointment with a physician which you
          have no intention of keeping; • create user accounts by automated
          means or under false or fraudulent pretenses; or• collect or store
          personal data about other users in connection with the prohibited
          activities described in this paragraph. In addition to our rights in
          these Terms of Use, we may take any legal action and implement any
          technical remedies to prevent the violation of this provision and to
          enforce these Terms of Use.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">CHANGES TO THESE TERMS OF USE</span>
        <span className="c8">
          . We may change these Terms of Use at any time, as we reasonably deem
          appropriate. Upon any change in these Terms of Use, we will post the
          amended agreement on the Site with or without notice of the changes.{" "}
        </span>
        <span className="c1">
          Your continued use of the Site and/or the Services following such
          changes shall constitute your affirmative acknowledgement of the Terms
          of Use, the modification and agreement to abide and be bound by the
          Terms of Use, as amended. It is your responsibility to review these
          terms and conditions on a regular basis. If at any time you choose not
          to accept these Terms of Use, including following any modifications
          hereto, then please do not use the Site.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">CHANGES TO THE SERVICES</span>
        <span className="c1">
          . We may from time to time add new services to the Services,
          substitute a new service for one of the existing Services, or
          discontinue or suspend one of the existing Services. Information about
          the new services will be included on the Site, and the users of new
          services will be governed by these Terms of Use. You agree that the
          Company, Anambra State e-Health Services care Limited will not be
          liable to you or any third party for any suspension or discontinuation
          of any of the Services.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">LINKS TO OTHER SITES.</span>
        <span className="c8">
          &nbsp;The Site may include links to other websites, including links
          provided as automated search results. Some of these sites may contain
          materials that are objectionable, unlawful, or inaccurate. These links
          are provided for your convenience only and do not mean that we endorse
          these sites or the products and services they provide. You acknowledge
          and agree that we are not responsible or liable for the content or
          accuracy of these other sites.{" "}
        </span>
        <span className="c3">ADDITIONAL TERMS</span>
        <span className="c8">. </span>
        <span className="c1">
          Certain of the Services on the Site may have additional terms (such as
          policies, guidelines, and rules) that will further govern your use of
          that particular Service and supplement these Terms of Use. If you
          choose to register for or access any such Services, you will be
          presented with any relevant additional terms and conditions at that
          time. By using those Services, you agree to comply with such
          additional guidelines and rules.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">NO SPAM</span>
        <span className="c8">.</span>
        <span className="c1">
          &nbsp;You may not use the contact information provided by our users or
          collaborating physicians, or harvest such information for the purpose
          of sending, or to facilitate the sending of unsolicited bulk
          communications such as SPAM. You may not allow others to use your
          account to violate the terms of this section. We may terminate your
          membership or access to the Site immediately and take other legal
          action if you or anyone using your Credentials violates these
          provisions.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">CONTENT YOU POST OR SUBMIT</span>
        <span className="c8">.</span>
        <span className="c1">
          &nbsp;You will have the opportunity to submit feedback regarding your
          experience with doctors who are featured on the Site, to submit
          inquiries concerning possible medical needs and to participate in
          other interactive and community features of the Site (collectively
          "Posted Information"). It is important that you act responsibly when
          providing Posted Information. In providing feedback, please give
          clear, honest information about the doctor and your experiences, but
          do not use inappropriate language, make gratuitous personal criticisms
          or comments or provide information that others could use to determine
          your identity. When participating in other interactive or community
          aspects of the Service, please do not post any information that
          another user or physician may use to identify you as an individual,
          but please do include all relevant information in a concise manner to
          help us provide you with a helpful response. We reserve the right to
          publish your Posted Information as part of the Service and to also
          remove your Posted Information for any reason. We are not, however,
          responsible for any failure or delay in removing Posted Information.
          Keep in mind that the Posted Information of others is simply opinion
          and should not be relied on. In addition: • You are solely responsible
          for any Posted Information that you submit, publish or display on the
          Site or transmit to other members and/or other users of the Site. •
          You may not post, distribute, or reproduce in any way any copyrighted
          material, trademarks, or other proprietary information without
          obtaining the prior written consent of the owner of such proprietary
          rights.You may not submit any content or material that infringes,
          misappropriates or violates the intellectual property, publicity,
          privacy or other rights of any party. • You may not provide any Posted
          Content that falsely expresses or implies that such content or
          material is sponsored or endorsed by 24Telemed. • You may not provide any Posted Content that is
          unlawful or that promotes or encourages illegal activity. • You
          understand and agree that Company, 24Telemed may (but is not obligated to) review and delete any
          Posted Content that, in the sole judgment of 24Telemed Limited, violates these Terms of Use or which might be
          offensive, illegal or that might violate the rights of, harm, or
          threaten the safety of other users or members of the Site and/or other
          website users. • You agree that you will only provide Posted Content
          that you believe to be true and you will not purposely provide false
          or misleading information.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          • By posting Posted Content on the Site, you agree to and hereby do
          grant, and you represent and warrant that you have the right to grant
          Anambra State e-Health Services care Limited, its contractors, and the
          users of the Site an irrevocable, perpetual, royalty-free, fully
          sublicensable, fully paid up, worldwide license to use, copy, publicly
          perform, digitally perform, publicly display, and distribute such
          Posted Content and to adapt, edit, translate, prepare derivative works
          of, or incorporate into other works, such Posted Content. This license
          is non-exclusive, except you agree that Company, 24Telemed, shall have the exclusive right to
          practice this license to the extent of combining your Posted Content
          with the Posted Content of other Evoke Ehealth Solutions Limited users
          for purposes of constructing or populating a searchable database of
          reviews and information related to the healthcare industry.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          • The following is a partial list of the kind of content and
          communications that are illegal or prohibited on/through the Site.
          Anambra State e-Health Services care Limited reserves the right to
          investigate and take appropriate legal action in its sole discretion
          against anyone who violates this provision, including without
          limitation, removing the offending communication from the Service and
          terminating the membership of such violators or blocking your use of
          the Services and/or the Site. You may not post content that: ◦ is
          false or intentionally misleading; ◦ harasses or advocates harassment
          of another person; ◦ involves the transmission of unsolicited mass
          mailing or "spamming"; ◦ violates the intellectual property or other
          rights of any person; ◦ is threatening, obscene, defamatory or
          libelous; or ◦ is pornographic or sexually explicit in nature.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">YOUR USE OF CONTENT.</span>
        <span className="c1">
          &nbsp;Except for Posted Content that you or any other visitors to the
          Site submit, all of the Information available on or through the
          Services and/or the Site, including without limitation, text,
          photographs, graphics and video and audio content, is owned by us and
          our licensors and is protected by copyright, trademark, patent, and
          trade secret laws, other proprietary rights, and international
          treaties. You acknowledge that the Services and any underlying
          technology or software used in connection with the Services contain
          Anambra State e-Health Services care Limited’s proprietary
          information. We give you permission to use the aforementioned content
          for personal, non-commercial purposes only and do not transfer any
          intellectual property rights to you by virtue of permitting your use
          of the Services. You may print, download, and store information from
          the Site for your own convenience, but you may not copy, distribute,
          re-publish (except as permitted in this paragraph), sell, or exploit
          any of the content, or exploit the Site in whole or in part, for any
          commercial gain or purpose whatsoever. Except as is expressly and
          unambiguously provided herein, Anambra State e-Health Services care
          Limited and its suppliers do not grant you any express or implied
          rights, and all rights in the Site and the Services not expressly
          granted by Anambra State e-Health Services care Limited to you are
          retained by Evoke Ehealth Solutions Limited.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">DISCLAIMER OF WARRANTIES</span>
        <span className="c8">.</span>
        <span className="c1">
          &nbsp;WE PROVIDE THE SITE AND THE SERVICES "AS IS", "WITH ALL FAULTS"
          AND "AS AVAILABLE." WE MAKE NO EXPRESS OR IMPLIED WARRANTIES OR
          GUARANTEES ABOUT THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
          WE HEREBY DISCLAIM ALL SUCH WARRANTIES, INCLUDING ALL STATUTORY
          WARRANTIES, WITH RESPECT TO THE SERVICES AND THE SITE, INCLUDING
          WITHOUT LIMITATION ANY WARRANTIES THAT THE SERVICES ARE MERCHANTABLE,
          OF SATISFACTORY QUALITY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR
          NEED, OR NON-INFRINGING. WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY
          BE OBTAINED FROM THE USE OF THE SERVICES WILL BE EFFECTIVE, RELIABLE
          OR ACCURATE OR WILL MEET YOUR REQUIREMENTS. WE DO NOT GUARANTEE THAT
          YOU WILL BE ABLE TO ACCESS OR USE THE SERVICES (EITHER DIRECTLY OR
          THROUGH THIRD-PARTY NETWORKS) AT TIMES OR LOCATIONS OF YOUR CHOOSING.
          WE ARE NOT RESPONSIBLE FOR THE ACCURACY, RELIABILITY, TIMELINESS OR
          COMPLETENESS OF THE INFORMATION PROVIDED BY USERS OF THE SITE OR ANY
          OTHER DATA OR INFORMATION PROVIDED OR RECEIVED THROUGH THE SITE.
          EXCEPT AS EXPRESSLY SET FORTH HEREIN, 24Telemed MAKES NO WARRANTIES ABOUT THE INFORMATION SYSTEMS,
          SOFTWARE AND FUNCTIONS MADE ACCESSIBLE THROUGH THE SITE OR ANY OTHER
          SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION.
          THE COMPANY, EVOKE EHEALTH SOLUTIONS LIMITED DOES NOT WARRANT THAT THE
          SITE OR THE SERVICES WILL OPERATE ERROR-FREE, THAT LOSS OF DATA WILL
          NOT OCCUR, OR THAT THE SERVICES SOFTWARE OR SITE ARE FREE OF COMPUTER
          VIRUSES, CONTAMINANTS OR OTHER HARMFUL ITEMS.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">GENERAL LIMITATION OF LIABILITY</span>
        <span className="c1">
          . YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE
          CANCELLATION OF YOUR REGISTRATION. IN NO EVENT SHALL OUR TOTAL
          CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OR
          ARISING OUT OF YOUR USE OF THE SERVICES OR THE SITE, REGARDLESS OF THE
          FORM OF ACTION, EXCEED THE GREATER OF: (A) THE TOTAL AMOUNT OF FEES,
          IF ANY, THAT YOU PAID TO UTILIZE THE SITE OR THE SERVICES OR (B)
          N10,0000. IN NO EVENT SHALL WE BE LIABLE TO YOU (OR TO ANY THIRD PARTY
          CLAIMING UNDER OR THROUGH YOU) FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF, OR
          INABILITY TO USE THE SITE AND/OR THE SERVICES. THESE EXCLUSIONS APPLY
          TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, WORK
          STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, ANY OTHER COMMERCIAL
          DAMAGES OR LOSSES, OR MEDICAL MALPRACTICE OR NEGLIGENCE OF PHYSICIANS
          UTILIZED THROUGH USE OF THE SERVICE, EVEN IF WE KNEW OR SHOULD HAVE
          KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. OUR LIABILITY SHALL BE
          LIMITED TO THE EXTENT PERMITTED BY LAWS OF THE FEDERATION OF NIGERIA.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">TERMINATION</span>
        <span className="c1">
          .We may terminate and/or suspend your registration immediately,
          without notice, if there has been a violation of these Terms of Use or
          other policies and terms posted on the Site by you or by someone using
          your Credentials. We may also cancel or suspend your registration for
          any other reason, including inactivity for an extended period, but
          will attempt to notify you in advance of such cancellation or
          suspension. The Company, Anambra State e-Health Services care LIMITED
          shall not be liable to you or any third party for any termination of
          your access to the Site and/or the Services. Further, you agree not to
          attempt to use the Site and/or the Services after any such deletion,
          deactivation or termination.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1" />
      </p>
      <p className="c4">
        <span className="c3 c9">TERMS OF USE.</span>
      </p>
      <p className="c2">
        <span className="c3 c9" />
      </p>
      <p className="c4">
        <span className="c3">INDEMNIFICATION.</span>
        <span className="c1">
          &nbsp;Upon a request by us, you agree to defend, indemnify, and hold
          harmless The Company, our employees, contractors, officers, directors,
          agents, parents, other affiliated companies, and suppliers, from all
          liabilities, claims, demands and expenses, including attorney's fees,
          made by any third party that arise from or are related to (a) your
          access to the Site, (b) your use of the Services, or (c) the violation
          of these Terms of Use by you or any third party using your
          Credentials, of any intellectual property or other right of any person
          or entity. The foregoing indemnification obligation does not apply to
          liabilities, claims and expenses arising as a result of our own gross
          negligence or intentional misconduct.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">ENTIRE AGREEMENT</span>
        <span className="c8">
          . These Terms of Use and any supplemental terms, policies, rules and
          guidelines posted on the Site, including the Privacy Policy below,
          constitute the entire agreement between you and us and supersede all
          previous written or oral agreements. If any part of these Terms of Use
          is held invalid or unenforceable, that portion shall be construed in a
          manner consistent with applicable law to reflect, as nearly as
          possible, the original intentions of the parties, and the remaining
          portions shall remain in full force and effect. The failure of the
          Company, Anambra State e-Health Services care LIMITED to exercise or
          enforce any right or provision of these Terms of Use shall not
          constitute a waiver of such right or provision. The failure of either
          party to exercise in any respect any right provided for herein shall
          not be deemed a waiver of any further rights hereunder.{" "}
        </span>
        <span className="c3">CHOICE OF LAW AND DISPUTE RESOLUTION</span>
        <span className="c8">
          . These Terms of Use shall be deemed to have been entered into and
          shall be construed and enforced in accordance with the laws of the
          Federal Republic of Nigeria as applied to contracts made and to be
          performed entirely within Nigeria. Any controversy, dispute or claim
          arising out of or related to these Terms of Use or your use of the
          Services shall be settled by final and binding arbitration to be
          conducted by an arbitration tribunal in Abuja, FCT, Nigeria, pursuant
          to the{" "}
        </span>
        <span className="c8 c6">The&nbsp;</span>
        <span className="c3 c6">Arbitration</span>
        <span className="c8 c6">
          &nbsp;and Conciliation Act 1988 (ACA) (Cap A18 Laws of the&nbsp;
        </span>
        <span className="c3 c6">Federation</span>
        <span className="c8 c6">&nbsp;of&nbsp;</span>
        <span className="c3 c6">Nigeria</span>
        <span className="c8 c6">&nbsp;2004)</span>
        <span className="c1">
          . The arbitration tribunal shall consist of Three arbitrators. The
          decision or award of the arbitrators shall be final, and judgment upon
          such decision or award may be entered in any competent court or
          application may be made to any competent court for judicial acceptance
          of such decision or award and an order of enforcement. The parties
          agree that the arbitrators shall have the authority to impose
          equitable and injunctive relief as well as to award monetary relief,
          as the arbitrators deems appropriate.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">ASSIGNMENT</span>
        <span className="c1">
          .We may assign this contract at any time to any parent, subsidiary, or
          any affiliated company, or as part of the sale, merger with, or other
          transfer of our company to another entity. We will post a notice on
          the Web Site regarding any change of ownership so that you have the
          opportunity to discontinue your use of the Site or cancel your
          registration if you do not wish to continue to use the Web Site and
          the Services under the new ownership. You may not assign, transfer or
          sublicense these Terms of Use to any one else and any attempt to do so
          in violation of this section shall be null and void.
        </span>
      </p>
      <p className="c4">
        <span className="c3">ELIGIBILITY.</span>
        <span className="c1">
          &nbsp;You must be 18 or over, or the legal age to form a Specialized
          contract/to register with us or use the Site and the Services. If you
          are between the ages of 13 and 18 or the applicable legal age in your
          jurisdiction, you can use the Site or Services only in conjunction
          with, and under the supervision of, your parent or guardian who has
          agreed to the Terms of Use. If you are under the age of 13, you may
          not use the Site or Services, in compliance with the Children's Online
          Privacy Protection Act. If you are the parent or legal guardian of a
          child under the age of 18, you may use the Site or Services on behalf
          of such minor child. By using the Site or Services on behalf of a
          minor child, you represent and warrant that you are the parent or
          legal guardian of such child. If you do not qualify under these terms,
          do not use the Site or Services.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          By using the Web Site and/or the Services, you represent and warrant
          that you have the right, authority, and capacity to enter into these
          Terms of Use and to abide by all of the terms and conditions set forth
          herein.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          The Web Site is administered in Nigeria and intended for Nigerian
          users; any use outside of Nigeria is at the user's own risk and users
          are responsible for compliance with any local laws applicable to their
          use of the Services or the Web Site.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">INFORMED CONSENT OF SERVICES.</span>
        <span className="c1">
          &nbsp;Online second opinions and Online consultative medical services
          are different from the services provided in person by a physician. The
          physician reviewing your case will not have the benefit of information
          that would be obtained by examining you in person and observing your
          physical condition. Therefore, the physician may not be aware of facts
          or information that would affect his or her opinion of your diagnosis.
          In some cases, the facts may be critical to the opinion. We therefore
          strongly encourage you to discuss the second opinion/ medical opinion
          with your treating physician. By proceeding with this service, you
          acknowledge that you are aware of this restriction and exclusively
          assume the risk of this restriction. You also Acknowledge that : The
          diagnosis received is limited and provisional, The second opinion
          service/ medical consultative service is not meant to replace a full
          medical evaluation in person. The consulting physician does not have
          important information that can be usually obtained on physical
          examination. The absence of the physical examination could affect the
          ability to render an exact opinion. The consulting physicians are
          licensed by the Board of Medicine in Nigeria and the country where
          they practice in their respective fields of medicine. By using our
          services, you agree to exclusively accept the risk of these
          limitations and understand that no guarantee has been made to you
          concerning any particular result or cure of your condition.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          Telemedicine involves the use of electronic communications to enable
          healthcare providers at different locations to share individual
          patient medical information for the purpose of improving patient care.
          Providers may include primary care practitioners, specialists, and/or
          subspecialists. The information may be used for diagnosis, therapy,
          follow-up and/or education, and may include any of the following:
          Patient medical records, Medical images, Live two-way audio and video,
          Output data from medical devices and sound and video files. Electronic
          systems used will incorporate network and software security protocols
          to protect the confidentiality of patient identification and imaging
          data and will include measures to safeguard the data and to ensure its
          integrity against intentional or unintentional corruption.
          Responsibility for the patient care should remain with the patient's
          local clinician, if you have one, as does the patient's medical
          record.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">EXPECTED BENEFITS.</span>
        <span className="c1">
          &nbsp;Access to medical care opinion by enabling a patient to remain
          in his/her local healthcare site (i.e. home) Efficient telephonic
          medical evaluation and providing management and/or treatment
          options.Obtaining expertise of a foreign (US and Ireland) based
          Specialist.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">POSSIBLE RISKS.</span>
        <span className="c1">
          &nbsp;As with any medical procedure, there are potential risks
          associated with the use of telemedicine. These risks include, but may
          not be limited to: In rare cases, the consultant may determine that
          the transmitted information is of inadequate quality, thus
          necessitating a face-to-face meeting with the patient, or at least a
          rescheduled video consult; Delays in medical evaluation and treatment
          could occur due to deficiencies or failures of the equipment; In very
          rare instances, security protocols could fail, causing a breach of the
          privacy of personal medical information; In rare cases, a lack of
          access to complete medical records may result in adverse drug
          interactions or allergic reactions or other judgment errors; By
          checking the box associated with Terms of use, you are giving us an
          "Informed Consent". You acknowledge that you understand and agree with
          the following: I understand that the laws that protect privacy and the
          confidentiality of medical information also apply to telemedicine, and
          that no information obtained in the use of telemedicine, which
          identifies me, will be disclosed to researchers or other entities
          without my consent. I understand that I have the right to withhold or
          withdraw my consent to the use of telemedicine in the course of my
          care at any time, without affecting my right to future care or
          treatment. I understand the alternatives to telemedicine consultation
          as they have been explained to me, and in choosing to participate in a
          telemedicine consultation, I understand that some parts of the exam
          involving physical tests may be conducted by individuals at my
          location, or at a testing facility, at the direction of the consulting
          healthcare provider. I understand that telemedicine may involve
          electronic communication of my personal medical information to other
          medical practitioners who may be located in other areas, including out
          of Nigeria. I understand that I may expect the anticipated benefits
          from the use of telemedicine in my care, but that no results can be
          guaranteed or assured. I understand that my healthcare information may
          be shared with other individuals for scheduling and billing purposes.
          Others may also be present during the consultation other than my
          healthcare provider and consulting healthcare provider in order to
          operate the video equipment. The above mentioned people will all
          maintain confidentiality of the information obtained. I further
          understand that I will be informed of their presence in the
          consultation and thus will have the right to request the following:
          (1) omit specific details of my medical history/physical examination
          that are personally sensitive to me; (2) ask non-medical personnel to
          leave the telemedicine examination room; and/or (3) terminate the
          consultation at any time.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">PATIENT CONSENT TO THE USE OF TELEMEDICINE</span>
        <span className="c1">
          . I have read and I understand the information provided above
          regarding telemedicine, have discussed it with my physician or such
          assistants as may be designated, and all of my questions have been
          answered to my satisfaction. I have read this document carefully, and
          understand the risks and benefits of the teleconferencing consultation
          and have had my questions regarding the procedure explained and I
          hereby give my informed consent to participate in a telemedicine visit
          under the terms described above.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          You retain the option to withhold or withdraw consent to receive
          health care services via the Online Services at any time without
          affecting your right to future care or treatment nor risking the loss
          or withdrawal of any benefits to which you or your legal
          representative would otherwise be entitled. All existing
          confidentiality protections apply. All existing laws regarding patient
          access to medical information and copies of medical records apply.
          Dissemination of any of your identifiable images or information from
          the telemedicine interaction to researchers or other entities shall
          not occur without your consent. All provisions of these Terms of Use,
          including your informed consent to receive services via the Online
          Services are for the benefit of the treating Provider as well as for
          your benefit.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">NOTICE </span>
        <span className="c1">
          Medical doctors are licensed and regulated by the Medical and Dental
          Council of Nigeria. Each Provider's hours are variable. To access a
          Provider's in-office schedule, go to that Provider's login page where
          the Provider's in-office hours are posted. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          I expressly consent to Providers forwarding my patient identifiable
          information to Anambra State e-Health Services care/ second Opinion’s
          designee. I agree that I will hold harmless 24Telemed Limited, The Boolean Technologies Nig. Ltd, and each
          Provider for any loss of information due to a technical failure.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1 c10">
          Notice Concerning Complaints You may file a complaint
          online?????????????????{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1 c10">
          Questions and concerns regarding this professional practice may be
          directed to: ????????????????{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          The relationship between you and the Provider is not intended to
          replace the relationship between you and other providers. The
          relationship between you and the Provider is supplemental. Your
          primary care physician is responsible for your overall health care
          management. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          Our procedure to verify the identification of the individual
          transmitting the communication: We verify your identification through
          the assignment and use of a unique username and password combination.
          When you sign into Anambra State e-Health Services care Limited, your
          username and password identify you.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          Access to Anambra State e-Health Services care Limited data is
          restricted through the use of unique usernames and passwords. The
          username and password assigned to you are personal to you and you must
          not share them with any other individual.You can change your password
          if you so desire.
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          Patient access to all medical information transmitted during a
          telemedicine interaction is guaranteed by the provider and copies of
          this information are available at stated costs, which shall not exceed
          the direct cost of providing the copies.All existing confidentiality
          protections apply.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">DISSEMINATION</span>
        <span className="c1">
          . Any of your identifiable images or information from the telemedicine
          interaction to researchers or other entities shall not occur without
          your consent. By agreeing with these Terms of Use, you acknowledge
          that you understand these provisions.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c3 c9" />
      </p>
      <p className="c4">
        <span className="c3">PRIVACY POLICY. </span>
        <span className="c8">
          Introduction: The Company, Anambra State e-Health Services care
          Limited, is committed to respecting the privacy rights of our
          patients,customers, visitors, and other users of the Evoke Ehealth
          Solutions Limited Expert Medical/ Second Opinion, services
          ("Services") provided via the Anambra State e-Health Services care
          Limited websites (collectively, "Website"). The term Website refers to
          services located at{" "}
        </span>
        <span className="c7 c11">
          <a
            className="c12"
            href="https://www.google.com/url?q=https://evokehealth.netlify.app/home&sa=D&source=editors&ust=1633087194173000&usg=AOvVaw3qq8tbqbvnM5BU3ceA_Rc1"
          >
            https://evokehealth.netlify.app/home
          </a>
        </span>
        <span className="c1">
          ("Web Site, Website, Site, site") and All affiliated websites Owned,
          Operated, Licensed or Controlled by 24Telemed (“Company”). We created this Website Privacy Policy
          ("Privacy Policy") to give you confidence as you visit and use the
          Website, and to demonstrate our commitment to fair information
          practices and the protection of privacy. This Privacy Policy is only
          applicable to the Website, and not to any other websites that you may
          be able to access from the Website or any website of 24Telemed partners, each of which may have data
          collection, storage, and use practices and policies that differ
          materially from this Privacy Policy. Your use of the Website is
          governed by this Privacy Policy and the Terms of Use.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Traffic Data Collected </span>
        <span className="c1">
          We automatically track and collect the following categories of
          information when you visit our Website: (1) IP addresses; (2) domain
          servers; (3) types of computers accessing the Website; (4) types of
          web browsers used to access the Website; (5) referring source which
          may have sent you to the Website; and (6) other information associated
          with the interaction of your browser and the Website (collectively
          "Traffic Data”).{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Personal Information Collected</span>
        <span className="c1">
          . In order for you to access certain areas of the Website, we may
          require you to provide us with certain information that personally
          identifies you ("Personal Information"). Personal Information includes
          the following categories of information: (1) Contact Data (such as
          your email address, phone number and login password); (2) Demographic
          Data (such as your gender, your date of birth and your zip code); (3)
          Insurance Data (such as your insurance carrier and insurance plan);
          and (4) Medical Data (such as your previous doctors and dentists
          ("Providers") visited, your reason for visit and your date of visit,
          and other medical information you choose to share with us). If you
          communicate with us by, for example, email or letter, any information
          provided in such communication may be collected as Personal
          Information.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Interactive and Installed Tools</span>
        <span className="c1">
          . We also collect other information, some of which may be Personal
          Information that you voluntarily provide to us when you choose to use
          some of the Website's interactive tools and services ("Interactive
          Tools"), such as searching for Doctors and appointments with them. We
          also collect information you provide voluntarily in free-form text
          boxes on the Website and through responses to surveys, questionnaires
          and the like. Some of the free Interactive Tools on our site ask you
          for health-related Personal Information. You may elect to use certain
          Services available through the Website that require you to download
          and install the software on your computer ("Installed Tools"). The
          Installed Tools may collect and transmit information from your
          computer system solely relating to use of the Installed Tools and for
          the purpose of providing you the relevant Services, for example, by
          informing when a user is logged on and available to receive update or
          alert notices.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Cookies.</span>
        <span className="c1">
          "Cookies" are small computer files that are transferred to your
          computer's hard drive that contain information such as user ID, user
          preferences, lists of pages visited and activities conducted while
          browsing the Website. At your option, expense and responsibility, you
          may block cookies or delete cookies from your hard drive. However, by
          disabling cookies, you may not have access to the entire set of
          features of the Website. Generally, we use "cookies" to customize your
          experience on our Website and to store your password so you do not
          have to re-enter it each time you visit the Website.
        </span>
      </p>
      <p className="c4">
        <span className="c3">
          The Company, Anambra State e-Health Services care LTD Use of Your
          Information
        </span>
        <span className="c1">
          . We may use your Contact Data to send you information about the
          Company,Anambra State e-Health Services care LTD or our products or
          Services, to contact you when necessary, including to remind you of
          upcoming or follow-up appointments, and in conjunction with your use
          of certain Interactive Tools, We may use your Demographic Data, your
          Traffic Data, your Insurance Data or your Medical Data to customize
          and tailor your experience on the Website, in emails and other
          communications, displaying content that we think you might be
          interested in and according to your preferences. You agree that
          Anambra State e-Health Services care LTD may use Personal Information
          to allow your doctors to make appointments with other doctors on your
          behalf through the Services.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Sharing of Information</span>
        <span className="c1">
          &nbsp;We share certain categories of information we collect from you
          in the ways described in this Privacy Policy. We may share Demographic
          Data with advertisers and other third parties only on an aggregate
          (i.e., non-personally-identifiable) basis. We may also share your
          Contact Data, Demographic Data, Insurance Data and Medical Data with
          Doctors you choose to schedule on our Website. We also share Personal
          Information and Traffic Data with our business partners who assist us
          by performing core services (such as hosting, billing, fulfillment, or
          data storage and security) related to our operation of the Website
          and/or by making certain Interactive Tools available to our users.
          Those business partners shall be bound to uphold the same standards of
          security and confidentiality that we have promised to you in this
          Privacy Policy, and they will only use your Contact Data and other
          Personal Information to carry out their specific business obligations
          to Anambra State e-Health Services care LTD and to provide your
          requested medical care and services.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c1">
          Last, we may transfer information about you to another company in
          connection with a merger, sale or acquisition by or of the Company,
          Anambra State e-Health Services care LTD. In this event, we will use
          reasonable efforts to notify you before information about you is
          transferred and becomes subject to a different privacy policy. The
          Company, Anambra State e-Health Services care LTD does not share,
          sell, rent or trade your Personal Information with any third parties
          for their promotional purposes.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">User Choice.</span>
        <span className="c1">
          &nbsp;You may choose not to provide us with any Personal Information.
          In such an event, you can still access and use much of the Website;
          however, you will not be able to access and use those portions of the
          Website that require your Personal Information. You have the right to
          request copies of available Personal information on record as well as
          request to erase them out of the system. Requests can be directed to
          the Data Protection Officer and sent electronically or by mail to our
          office.
        </span>
      </p>
      <p className="c4">
        <span className="c3">Confidentiality and Security</span>
        <span className="c1">
          . Except as otherwise provided in this Privacy Policy, we will keep
          your Personal Information private and will not share it with third
          parties, unless we believe in good faith that disclosure of your
          Personal Information or any other information we collect about you is
          necessary to: (1) comply with a court order or other legal process;
          (2) protect the rights, property or safety of or another party; (3)
          enforce our Terms of Use; or (4) respond to claims that any posting or
          other content violates the rights of third-parties.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Physicians.</span>
        <span className="c1">
          &nbsp;Physicians, their employees, and their agents should be
          particularly aware of their obligations of patient confidentiality,
          including without limitation, their obligations under HIPPA,Irish
          Medical Organisation and Section 44 of the Code of Medical Ethics in
          Nigeria and also in communicating with the Company, Evoke Ehealth
          Solutions Ltd and in responding to a review of their services posted
          on our Website,The Company, Anambra State e-Health Services care Ltd
          does not have, and will not accept, any obligations of confidentiality
          with respect to any communications other than those expressly stated
          in this Privacy Policy and the Company’s Terms of Use.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Public Information.</span>
        <span className="c1">
          &nbsp;Any information that you may reveal in a review posting or other
          online discussion or forum is intentionally open to the public and is
          not in any way private. You should think carefully before disclosing
          any personally identifiable information in any public forum. What you
          have written may be seen and/or collected by third parties and may be
          used by others in ways we are unable to control or predict.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Security</span>
        <span className="c8">
          . The security of your Personal Information is important to us. We
          follow generally accepted industry standards to protect the Personal
          Information submitted to us, both during transmission and once we
          receive it. We make good faith efforts to comply with Data protection
          laws like the GDPR and particularly NDPR. For example, when you enter
          sensitive information on our Website, we encrypt that information
          using secure socket layer technology (SSL).Although we make good faith
          efforts to store Personal Information in a secure operating
          environment that is not open to the public, you should understand that
          there is no such thing as complete security, and we do not guarantee
          that there will be no unintended disclosures of your Personal
          Information. If we become aware that your Personal Information has
          been disclosed in a manner not in accordance with this Privacy Policy,
          we will use reasonable efforts to notify you of the nature and extent
          of the disclosure (to the extent we know that information) as soon as
          reasonably possible and as permitted by law.{" "}
        </span>
        <span className="c3">Lost or Stolen Information.</span>
        <span className="c1">
          &nbsp;You must promptly notify us if your Contact Data is lost,
          stolen, or used without permission. In such an event, we will remove
          that Contact Data from your account and update our records
          accordingly.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Updates and Changes to Privacy Policy</span>
        <span className="c1">
          . We reserve the right, at any time, to add to, change, update, or
          modify this Privacy Policy so please review it frequently. These
          changes would be binding to you by using our website. If we do, then
          we may or may not notify you here, and/or post a notice on our
          Website, with a description of any changes (material or otherwise)
          and, where appropriate, a link to the modified policy so that you can
          review it. In all cases, the use of information we collect is subject
          to the Privacy Policy in effect at the time such information is
          collected.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Controlling Your Personal Information.</span>
        <span className="c1">
          &nbsp;As a registered user of the Website, you can modify some of the
          Personal Information you have included in your profile or change your
          username by logging in and accessing your account. Upon your request,
          the Company, Anambra State e-Health Services care Ltd will use
          commercially reasonable efforts to delete your account and Personal
          Information in your profile; however, it may be impossible to remove
          your account without some residual information being retained by the
          Company, Anambra State e-Health Services care Ltd.{" "}
        </span>
      </p>
      <p className="c4">
        <span className="c3">Links to Other Websites.</span>
        <span className="c8">
          &nbsp;The Website contains links to third party websites to which
          Anambra State e-Health Services care Ltd has no affiliation. The
          Company, Evoke Ehealth Solutions Ltd does not share your personal
          information with those websites and is not responsible for their
          privacy practices. Some web sites may have the look and feel of our
          Website. Please be aware that you may be on a different site and that
          this Privacy Policy only covers our Website. Should you decide to
          visit one of these third party websites, we suggest that you read its
          privacy policy.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c1 c13" />
      </p>
    </div>
  );
};
