import React from 'react';
import ReactLoading from 'react-loading';

const PageLoader = ({ type = "spin", color = "#fff", loading=true }) => {
    return (

            <div style={{ 
                position: "fixed",
                top: 0, right: 0, bottom: 0, left: 0,
                background: "#37514B",
                display: loading ? "block" : "none",
                zIndex: 900000000,
                overflow: "hidden",
                height: "100vh"
            }}>
                <div style={{
                    margin: "auto",
                    width: "max-content",
                    marginTop: "calc(100vh / 2 - 25px)"
                }}>
                    <ReactLoading type={type} color={color} height={50} width={50} />
                </div>
            </div>
    )
}

export default PageLoader
