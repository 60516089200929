import React from 'react';

export const partners = [
	{
		logo: require("../media/img/logo-partner-1.png").default,
		link: "https://mayrockhealth.com/",
		details: "Mayrock (Posh) - 100 Chime Avenue, New Haven, Enugu, Nigeria.",
		telephone: "+234 701 782 0969",		
	},
	{
		logo: require("../media/img/lb-partner.jpeg").default,
		link: "#",
		details: "6 O'Connor St, Asata 400102, Enugu, Nigeria",
		telephone: "+234 806 890 9404",		
	},	
	// {
	// 	logo: require("../media/img/logo-partner-2.png").default,
	// 	link: "https://h-medix.com/"
	// },
	{
		// logo: require("../media/img/logo-partner-3.png").default,
		logo: require("../media/img/figboss.jpg").default,
		link: "https://www.instagram.com/fitbossng/?hl=en",
	},
	// {
	// 	logo: require("../media/images/logo-partner-4.png").default,
	// 	link: "#", //"https://www.cdivineimaging.com/"
	// },
	{
		logo: require("../media/img/logo-partner-5.png").default,
		link: "#", //"https://www.cdivineimaging.com/"
		details: "Plot 288, IEC Crescent, behind ever green mall, Durumi, Abuja - FCT",
		telephone: "+234 906 254 7779"
	},
	{
		logo: require("../media/img/logo-partner-6.png").default,
		link: "#", //"https://www.cdivineimaging.com/"
		details: "Plot 576 Independence Ave, Central Business District 900103, Abuja",
		telephone: "+234 909 426 7360",
	},
	{
		logo: require("../media/img/HealthAssur.jpeg").default,
		link: "#", //"https://www.cdivineimaging.com/"
		details: "1, Ramayana Cres Ogunbite 105102, Lagos, Nigeria.",
		telephone: "+234 802 654 7348"
	},
	// {
	// 	logo: require("../media/img/vixa.png").default,
	// 	link: "#", //"https://www.cdivineimaging.com/"
	// 	details: "1, Ramayana Cres Ogunbite 105102, Lagos, Nigeria.",
	// 	telephone: "+234 802 654 7348"
	// },
	// vixa.png
];

export const team = [
	{
		name: "Dr. Anthony Oketah",
		designation: "MD, Co-founder, and CEO",
		image: require("../media/team/dr-anthony.jpg").default,
		details:
			"Dr Anthony Chinedu Oketah is specialized in psychiatry and emergency medicine in Ireland, UK.<br/><br/> He has over 20 years experience in medical practice and quality improvement.<br/><br/>Dr. Oketah’s passion is to ensure that the community that Nigerians get the medical attention they need by having easy access to medical solutions for their diagnosis. ",
	},
	{
		name: "Dr. Ifeoma Obianozie-Amadi",
		designation: "MD, Co-founder and Co-CEO",
		image: require("../media/team/dr-amadi.png").default,
		details:
			"Dr. Ifeoma Obianozie-Amadi is a board-certified Internal medicine specialist in the United States.<br/><br/> She has over 10 years of experience in improving patients experience and cost-effective quality care.<br/><br/> Her passion is to give back to the community that supported her journey to become a physician, and to support healthcare integration and continuum of care across the board.  ",
	},
	{
		name: "Dr. Nonye Egenti, MBBS, MD",
		designation: "Chief Medical Officer",
		image: require("../media/team/nonye.png").default,
		details: 
		"Dr. Nonye B. Egenti has been practicing medicine in Nigeria for the last 23 years.<br/><br/>\
		She received her medical degree from the Nnamdi Azikiwe University, Nnewi Campus in 1999, and became a   Fellow of the National Postgraduate Medical College of Nigeria since 2009.<br/><br/>\
		She also has a Master of Public Health degree from the University of Nigeria, Nsukka (2009).<br/><br/>\
		Dr Egenti is currently an Associate Professor, and Consultant Physician in the Department of Community Medicine and Primary Healthcare of the University of Abuja/University of Abuja Teaching Hospital.<br/><br/> She is a member of several notable associations including the Nigerian Medical Association (NMA), the Association of Public Health Physicians of Nigeria (APHPN), the Epidemiological Society of Nigeria (EPISON), International Epidemiological Association (IEA), amongst others."
	},
	{
		name: "Gertrude Ukachi Amadi, RN, RM, BNSc, MSc",
		designation: "Chief Nursing Officer",
		image: require("../media/team/dr-ukachi-amadi.png").default,
		details:
			"Gertrude Amadi is currently the deputy director nursing at the University of Nigeria Teaching Hospital, Ituku Ozalla, Enugu, Nigeria. Working currently at the psychiatric unit.<br/><br/> She completed her School of nursing, at the university of Nigeria teaching hospital. Enugu, Nigeria.<br/><br/> Since completing nursing school, Gertrude Amadi consistently dedicated her nursing career to serving her community.<br/><br/> Her consistent act of humanity contributed to her professional growth in the university teaching hospital where she currently works.<br/><br/> She is also the public relations officer, NANNM.<br/><br/> Gertrude’s passion is to create and maintain a healthcare integration pathway for physicians and nurses to collaboratively provide affordable and quality healthcare services to the community in Nigeria. ",
	},	
	{
		name: "Chinasa Ekete",
		designation: "Director of Operations",
		image: require("../media/team/chinasa.jpg").default,
		details:
			"Chinasa Ekete obtained her degree in University of Nigeria Nsukka and has over 3 years experienced in business development.<br/><br/> She is experienced in financial analysis, marketing, and sales strategies with excellent communication skills, organizing and planning ability.<br/><br/>Chinasa Ekete manages the non-clinical workflow of 24Telemed alongside the company co-founders.",
	},
	{
		name: "Chisom Amadi",
		designation: "Director, Customer and Patient experience.",
		image: require("../media/team/dr-chisom-amadi.png").default,
		details:
			"Miss Chisom Amadi is a graduate of Rowan University in NJ, U.S.A where she got her minor in psychology and majored in biological sciences.<br/><br/>  She is an aspiring dental student currently in the process of completing her MBA in healthcare management.<br/><br/> She has experience working with patients and assisting physicians from previous medical opportunities she participated in.<br/><br/> Her passion is to give back to her community by ensuring that customers and patients have great experience with Evoke ehealth team.",
	},	
	{
		name: "Miss Rahila Rachel Adamu Gandu AKA Bose",
		designation: "Director of Home Care Services",
		image: require("../media/team/dr-rahila.JPG").default,
		details:
			"Miss Adamu is a graduate of Ahmadu Bello University Zaria where she studied and had her degree in business administration.<br/><br/> She migrated to Ireland in 2003, and since then have been a social support worker since 2005 till date.<br/><br/> She obtained her diploma in health care at Progressive College  Dublin and an Advance diploma in social care 2007 University of carlow.  <br/>Miss Adamu is experienced in different fields as a support worker 'care for the  elderly and children nursing homes,residential and hospital.<br/><br/> Her passion is to give back to her community and ensure that people get the care they deserve both at home and in the hospital. ",
	},
];

export const testimonial = [
	{
		comment:
			"The second medical opinion service is excellent. I was connected to a specialist abroad within 24hrs to discuss my symptoms. The following week I was abroad and was seen by a hematologist for my blood disorder. For my second call, I was scheduled to see a gastroenterologist for a screening colonoscopy abroad. Most of my appointments abroad were scheduled before my arrival. I never had to wait more than a few days for my appointments. 24Telemed physicians and the specialists I was referred to combine expertise and a willingness to listen and discuss.",
		name: "Mr. Richard Ekwenibe AKA Onowu Nen",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"It took me weeks to months to schedule to see doctors abroad for a simple physical examination. With second medical opinion service, I was able to have my physical exams scheduled before traveling abroad. I flew into USA had an ophthalmologist examine my eyes and was able to get back to my business in Nigeria within a week. The services received from both evoke ehealth second opinion and the specialists was amazing.",
		name: "Mr. Christopher Anunagba AKA Osuofia",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"Dr. Amadi was terrific. Knowledgeable, sensitive, informative… I immediately felt at ease – and felt confident in receiving expert medical opinion for my symptoms. I was very impressed with the overall experience. HIGHLY recommend.",
		name: "Mr. Amechi Madueme",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"I wish my general practitioner can sign up to use the eclinic platform for virtual visits with my family. Amazing service from the second medical opinion doctors. No hassles, no fear of driving to the doctor’s office and waiting to be seen. Just a phone call and my adult son’s medical condition was addresses.<br/>I sent a picture of my injuries via secured site and within minutes received a second medical opinion for my injuries. Amazing!",
		name: "Mrs. Nkiru Nwankwo",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"Dr. Oketah is a great doctor! He’s very understanding and listens to your concerns. He takes time with the patient to help them with their health issues! I highly recommend him to anyone looking for second medical opinion. I was receiving anticoagulation for presumed DVT because of swollen leg, but when I called second opinion service and spoke with Dr Anthony Oketah, his expert opinion saved me from continuing anticoagulation. I had a tendon rupture which was taken care of by a specialist.",
		name: "Eng. Festus",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"One of the specialists abroad reviewed my labs and gave medical opinion based on our discussions. I feel much better! <br/> The doctors are extremely professional: They take time to listen and time to explain. A first-rate professional experience. Completely satisfied with the services and all the support staff. Highly recommended. This is the future of medicine in west Africa. I urge physicians of different specialties in west Africa to use the eclinic platform for patient care convenience.",
		name: "Anonymous",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			" I live abroad and received a call from Nigeria about my father’s illness. Because the doctors in Nigeria were on strike, I called the second medical opinion service and got connected to a doctor in less than 1 hour. The doctor online listened patiently to my father’s condition and gave expert medical opinion on what to do. I felt reassured that my dad was safe. My dad recovered without seeing a doctor in person. I highly recommend this team.",
		name: "Anonymous",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"I highly recommend using 24Telemed services. I got multiple referrals and excellent care from their services. Dr Oketah is very professional, caring, and listened. I had multiple appointments scheduled abroad for my medical conditions and for my wife’s surgery. Amazing service…",
		name: "Chief Iloka Ugochukwu Ernest",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"we have great specialists in Nigeria. I called for advise and was referred to a neurologist in Abuja! Saved me the cost of traveling abroad.",
		name:"Anonymous",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"I had a great experience with a urologist I was referred to in Asaba, Nigeria. Clean office and exceptional service!",
		name:"Anonymous",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"Second opinion doctors referred me back to a gastroenterologist in Lagos Nigeria where I  got my colonoscopy done. I got exceptional care. I’m recommending the specialist for my friends and family!",
		name:"Anonymous",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"A neuro surgeon in Abuja removed a tumor from my brain at an affordable cost. Within a week I was fully recovered and discharged home. The nurses were kind and helpful, I had great care in the hospital!",
		name:"Ebuka Mighty",
		image: require("../media/no-image-resulta.jpg").default,
	},
	{
		comment:
			"My father is elderly and had a stroke. A nurse was sent to the house to assist him with checking his vitals and making sure he was taking his medications. He was monitored at home. It was a huge relief for me.",
		name:"Anonymous",
		image: require("../media/no-image-resulta.jpg").default,
	}
];

export const RadioPartners = [
	{
		name: "BT Health and Diagnostic Centre",
		details: "15 Oba Akinjobi Way, Ikeja GRA 101233, Ikeja, Lagos",
		telephone: "+234 908 833 1324",
		image: require("../media/partner/BT.png").default,
	},
	{
		name: "Healthrite diagnostics",
		details: "Lake View Park Ajah Lagos",
		telephone: "+234 906 944 6743<br/>+234 907 118 6790",
		image: require("../media/partner/healthrite.png").default,
	},
	{
		name: "Firmcare diagnostics",
		details: "Area 11, Tswanya Center Suite A3 & A4, Mohammadu Buhari Way, Garki, Abuja",
		telephone: "+234 808 149 9391",
		image: require("../media/partner/firmcare.png").default,
	},
	{
		name: "Rovich Diagnostic Services",
		details: "Ziks Avenue, 420108, Awka, Nigeria",
		telephone: "+234 706 378 7826",
		// image: require("../media/team/dr-anthony.jpg").default,
	},
	{
		name: "Reene Diagnostics",
		details: "50 Ezeiweka Rd, Army Barracks 434104, Obosi, Nigeria",
		telephone: "+234 803 598 5878",
		// image: require("../media/team/dr-anthony.jpg").default,
	},
	{
		name: "Mayrock Diagnostics",
		details: "No. 10 Ocheze Street (Opposite Zecon Filling Station), Omeife, Onitsha.",
		telephone: "+234 701 782 0969",
		image: require("../media/partner/mayrock.png").default,
	},
	{
		name: "Mayrock Lab",
		details: "Mayrock (Posh) - 100 Chime Avenue, New Haven, Enugu, Nigeria.",
		telephone: "+2347017820969",
		image: require("../media/partner/mayrock.png").default,
	},
	{
		name: "Lifebridge Lab",
		details: "6 O'Connor St, Asata 400102, Enugu, Nigeria",
		telephone: "+234 806 890 9404",
		image: require("../media/partner/lb.png").default,
	},	
	{
		name: "Alpha Medical Services",
		details: "6 Gbogi Street, 340110, Akure, Nigeria",
		telephone: "+234 806 041 1641",
		image: require("../media/partner/AlphaDiag.png").default,
	},
	{
		name: "Abbos Medical Laboratory",
		details: "7 Igiolugbin Street, Bariga, Lagos",
		telephone: "+234 902 398 9187<br/>+234 806 014 4852<br/>+234 909 937 3304",
		image: require("../media/partner/Abbos.png").default,
	},	
]
export const PharmPartners = [
	{
		name: "Healthrite Pharmacy",
		details: "Lake View Park Ajah Lagos",
		telephone: "+234 906 944 6743, +234 907 118 6790",
		image: require("../media/partner/healthrite.png").default,
	},
	{
		name: "Baydel Pharmacy",
		details: "6 Borno St, Garki 900247, Abuja, Nigeria.",
		telephone: "+234 813 817 8900",
		// image: require("../media/team/dr-anthony.jpg").default,
	},
	{
		name: "Alpha Pharmacy & Stores",
		details: "Nourisha Fast Food, No 1 Golphins Close, Awka Anambra.",
		telephone: "+234 906 244 0941",
		image: require("../media/partner/AlphaPharmacy.png").default,
	},
	{
		name: "Dezubic pharmacy",
		details: "20 Ondo Road, 340110, Akure, Nigeria",
		telephone: "+234 817 971 5191",
		image: require("../media/partner/dezubic.png").default,
	},	
	{
		name: "Vixa Pharmaceutical Company Limited",
		details: "13b, Sunny Jigide Street, Off Celestial Way, Ogudu, Lagos, Nigeria",
		telephone: "+234 815 065 1200, +234 8150651199, +234 8150651205",
		image: require("../media/partner/vixa.png").default,
	},
];
export const SuppliesPartners = [
	{
		name: "Obino Marco Medical Unit",
		details: "34, Kalani street, off Luth Rd, Mushin 100253, Lagos, Nigeria",
		telephone: "+234 803 671 8991",
		// image: require("../media/partner/healthrite.png").default,
	},
	{
		name: "Fab Medicals Limited",
		details: "26 Mobolaji Bank Anthony Way, Maryland 100275, Lagos, Nigeria",
		telephone: "+234 806 092 9393",
		image: require("../media/partner/fab.png").default,
	},
	{
		name: "EUIAC PREMIUM NIGERIA",
		details: "Olusegun Obasanjo Way, Wuye 190001, Abuja, Nigeria",
		telephone: "+234 802 060 1000",
		image: require("../media/partner/EUIAC.png").default,
	},
	{
		name: "SCANTRIK MEDICAL SUPPLIES",
		details: "Plot No. 17 Oladipo Diya Street, Gudu District, 900104, Abuja, Nigeria",
		telephone: "+234 907 643 3020",
		image: require("../media/partner/SCANTRIK.png").default,
	},
	{
		name: "Endurance Medical And Pharmaceuticals Nig",
		details: "Bridgehead Market, G15 b Ogbo Ogwu, Fegge, Nigeria",
		telephone: "+234 813 869 4831",
		// image: require("../media/partner/dezubic.png").default,
	},
	{
		name: "Healthcare Equipment Limited",
		details: "31 Port Harcourt - Aba Expy, beside Leventis Bus Stop, Woji 500101, Port Harcourt, Nigeria",
		telephone: "+234 817 415 4891",
		image: require("../media/partner/hcelos.png").default,
	},
	{
		name: "Halomedicals Systems Ltd",
		details: "3 Grace Avenue, off Obiwali Rd, 500272, Port Harcourt, Nigeria",
		telephone: "+234 803 886 7562",
		image: require("../media/partner/halomedicals.png").default,
	},
	{
		name: "Nelcom Medical equipment supplier",
		details: "1 Chief Stephen Obi Cresent, Independence Layout 400102, Enugu, Nigeria",
		telephone: "+234 701 399 8015",
		// image: require("../media/partner/dezubic.png").default,
	},
	{
		name: "Medlife Plus Limited",
		details: "3 Aria Road, Off Kingsway Rd, GRA, Enugu, Nigeria",
		telephone: "+234 703 914 8224",
		image: require("../media/partner/medlife.jpg").default,
	},
	{
		name: "Wilpez Medical Supplies",
		details: "Suite B1 Ultimate Plaza, old INEC Rd, 420110, Awka, Nigeria",
		telephone: "+234 810 176 4312",
		image: require("../media/partner/wilpez.jpg").default,
	},
];