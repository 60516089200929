import React, { useState, useEffect } from "react";
import { Layout } from "../components/Layout";
import { Accordion } from "../components/Accordion";
import Axios from "axios";

const data = {
  faq: require("../data/faq.json"),
};
const { faq } = data;
export const FAQ = () => {

  return (
    <Layout
      subtitle={""}
      title={"Frequently Asked Questions."}
      bgimage={require("../media/img/pexels-cottonbro-7578799.jpg").default}
      jumpto="contact"
    >
      <section id="contact" className="bg-light-gray">
        <div className="container">
          <div className="row row-cols-1 justify-content-center">
            {/* start fancy text box item */}
            {/* <div className="col md-margin-30px-bottom xs-margin-15px-bottom"> */}
              {/* <div className="feature-box feature-box-hide-show-hover bg-white border-radius-6px overflow-hidden"> */}
                <div className="w-100 padding-5-rem-lr padding-15px-tb lg-padding-2-half-rem-lr md-padding-4-half-rem-lr">
                  <div className="feature-box-content last-paragraph-no-margin">
                  {/* {Object.entries(faq).map((item, index) => <Accordion title={item[0]} detail={item[1]} id={`accordion-style-03-0${index}`} />)} */}
                  {Object.entries(faq).map((item, index) => <Accordion title={item[0]} detail={item[1]} index={index+3} />)}
                  </div>
                </div>
                <div className="feature-box-overlay" />
              {/* </div> */}
            {/* </div> */}
            {/* end fancy text box item */}
           
          </div>
        </div>
      </section>
      {/* end section */}
    </Layout>
  );
};



