import React from "react";
import { apiGet } from "../../api/connect";
import { formatDate } from "./BlogList";

export const postLink = (post, slug="blogs") => {
    return  `/${slug}/${post?.id}/${post?.slug}`
}


export const BlogItem1 = ({ post }) => {
	const [media, setMedia] = React.useState();
    const [categories, setCagories] = React.useState([])

	React.useEffect(() => {
		if(post?.featured_media){
            apiGet({}, "/media/" + post?.featured_media).then((res) => {
                setMedia(res?.guid?.rendered);
            });
            apiGet({}, "/categories?post=" + post?.id).then((res) => {
                setCagories(res);
            });
        }
	}, []);
	return (
		<div
			className="swiper-slide cover-background lg-h-500px xs-h-300px"
			style={{
				backgroundImage: `url(${
					media ||
					"https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-6.jpg"
				})`,
			}}
		>
			<div className="d-lg-flex align-items-center bg-transparent-black slider-caption-blog-caption padding-55px-tb xl-padding-20px-tb lg-padding-55px-tb md-padding-40px-tb xs-padding-30px-tb padding-60px-lr xl-padding-50px-lr xs-padding-30px-left xs-padding-50px-right">
				<a
					href={postLink(post)}
					className="col-auto text-uppercase ps-0 padding-2-rem-right margin-2-rem-right border-right border-color-dark-white-transparent text-yellow-ochre text-dark-hover text-medium font-weight-500 letter-spacing-2px alt-font md-no-border-right md-margin-10px-bottom"
				>
                    {categories?.length > 0 && categories[0].name}
				</a>
				<h6 className="m-0">
					<a
						href="blog-post-layout-01.html"
						className="text-white alt-font font-weight-300"
					>
						{post?.title?.rendered}
					</a>
				</h6>
			</div>
		</div>
	);
};

export const BlogItem2 = ({ post }) => {
	const [media, setMedia] = React.useState();
    const [categories, setCagories] = React.useState([])

	React.useEffect(() => {
		if(post?.featured_media){
            apiGet({}, "/media/" + post?.featured_media).then((res) => {
                setMedia(res?.guid?.rendered);
            });
            apiGet({}, "/categories?post=" + post?.id).then((res) => {
                setCagories(res);
            });
        }
	}, []);

	return (
		<div className="col-xl-6 wow animate__fadeIn blog-section-summary-item" 
            >
			<div className="blog-post">
				<div className="blog-post-image bg-dark-slate-blue">
					<img
						src={
							media ||
							"https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-6.jpg"
						}
						alt=""
					/>
					<div className="blog-overlay" />
				</div>
				<div className="post-details d-flex flex-column align-item-start padding-3-half-rem-all xl-padding-2-rem-all">
					<div className="mb-auto w-100">
						<a href="blog-grid.html" className="blog-category alt-font text-dark">
                        {categories?.length > 0 && categories[0].name}
						</a>
					</div>
					<div className="align-self-end w-100">
						<a
                            href={postLink(post)}
							className="alt-font text-small d-inline-block text-dark text-uppercase opacity-6 margin-10px-bottom"
						>
							{formatDate(post?.date)}
						</a>
						{/* <a href="blog-grid.html" className="alt-font text-small d-inline-block text-dark text-uppercase opacity-6 margin-10px-bottom">28 February 2020</a> */}
						<a
							href={postLink(post)}
							className="alt-font text-white text-extra-large mb-0 d-block w-85 xl-w-100"
						>
							{post?.title?.rendered}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

const BlogSection = ({ hideTitle }) => {
	const [posts, setPosts] = React.useState([]);

	React.useEffect(() => {
		apiGet({}, "/posts").then((res) => {
			if(res?.length > 0) setPosts(res);
		});
	}, []);

	return (
		<section className="bg-light-iris-blue padding-100px-tb lg-padding-60px-tb md-padding-60px-tb sm-padding-50px-tb padding-90px-lr lg-padding-40px-lr xs-no-padding-lr blog-latest">
			<div className="container-fluid">
				{!hideTitle && (
					<div className="row justify-content-center">
						<div className="col-12 col-lg-6 text-center margin-2-half-rem-bottom sm-margin-1-half-rem-bottom">
							<h5 className="alt-font font-weight-600 text-extra-dark-gray margin-5px-bottom">
								Latest article
							</h5>
							<p>Explore our blog for insightful articles</p>
						</div>
					</div>
				)}

				<div className="row">
					<div className="col-12 col-xl-6 lg-margin-10px-bottom">
						<div className="position-relative h-100">
							<div
								className="swiper-container slider-caption-blog-image white-move"
								data-slider-options='{ "loop": true, "keyboard": { "enabled": true, "onlyInViewport": true }, "navigation": { "nextEl": ".caption-blog-next", "prevEl": ".caption-blog-prev" }, "effect": "slide" }'
							>
								<div className="swiper-wrapper">
									{posts.slice(0, 5).map((post, i) => {
										return <BlogItem1 key={"ibsh-svlog" + i} post={post} />;
									})}
								</div>
								{/* start slider navigation */}
								<div className="h-110px position-absolute w-100 bottom-0px xs-h-130px">
									<div className="caption-blog-next">
										<i className="feather icon-feather-arrow-right" />
									</div>
									<div className="caption-blog-prev">
										<i className="feather icon-feather-arrow-left" />
									</div>
								</div>
								{/* end slider navigation */}
							</div>
						</div>
					</div>
					<div className="col-12 col-xl-6">
						<div className="row blog-metro  grid-lxoading grid-2col xl-grid-2col lg-grid-2col md-grid-2col sm-grid-2col xs-grid-1col gutter-large home-magazine">

                {posts.slice(5, 9).map((post, i) => {
                        return (
                            <BlogItem2 key={"ibsh-svlog"+i} post={post} />
                        )
                    })}
              </div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogSection;
